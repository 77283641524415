.fade-in {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
}

.slide-left {
  &-exit,
  &-leave-to {
    transform: translateX(0);
  }

  &-enter,
  &-enter-from,
  &-exit-active,
  &-exit-done,
  &-leave-active {
    transform: translateX(-100%);
  }

  &-enter-active,
  &-exit-active,
  &-leave-active {
    transition: transform 0.5s ease-in-out;
  }

  &-enter-active,
  &-enter-done {
    transform: translateX(0);
  }
}
