:root {
  --calendar__max-width: 325px;
  --calendar__height: 264px;
  --calendar__min-width: 280px;
  --calendar-header__font-size: 14px;
  --calendar__border-color: var(--color-border-line-primary);

  /** Button **/
  --calendar-button__width: 30px;
  --calendar-button__font-weight: 500;
  --calendar-button__border-radius: 20px;
  --calendar-button__color: var(--color-text-field-active);
  --calendar-button__color-active: var(--color-white);
  --calendar-button__bg-hover: var(--color-border-line-primary);
  --calendar-button__bg-active: var(--color-interactive-action-primary);
  --calendar-button__bg-disabled: var(--color-text-accent-01);

  /** Header **/
  --calendar-header__border: 1px solid var(--color-border-line-primary);
  --calendar-header__grid: 36px 1fr 36px;
  --calendar-header-button__height: 40px;
  --calendar-header__padding: 10px 0;
  --calendar-header__text-color: var(--color-text-primary);
  --calendar-header__icon-color: var(--color-text-secondary);
  --calendar-header__button-width: 24px;
  --calendar-header__button-height: 24px;

  /** Weekday **/
  --calendar-weekday__height: 32px;
  --calendar-weekday__line-height: var(--calendar-weekday__height);
  --calendar-weekday__color: var(--color-text-secondary);

  /** Body **/
  --calendar-body__padding: 4px 5px 10px;

  /* Day */
  --calendar-day-weekend__color: #f03e3e;
  --calendar-day-inrange__color: #e8f3ff;
}
