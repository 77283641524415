.bodyXLargeBold {
  font-weight: var(--typography-body-xlarge-bold-font-weight);
  font-size: var(--typography-body-xlarge-font-size);
  line-height: var(--typography-body-xlarge-line-height);
  text-decoration: var(--typography-body-xlarge-text-decoration);
  font-style: var(--typography-body-xlarge-font-style);
  font-stretch: var(--typography-body-xlarge-font-stretch);
  letter-spacing: var(--typography-body-xlarge-letter-spacing);
}

.bodyXLargeMedium {
  font-weight: var(--typography-body-xlarge-medium-font-weight);
  font-size: var(--typography-body-xlarge-font-size);
  line-height: var(--typography-body-xlarge-line-height);
  text-decoration: var(--typography-body-xlarge-text-decoration);
  font-style: var(--typography-body-xlarge-font-style);
  font-stretch: var(--typography-body-xlarge-font-stretch);
  letter-spacing: var(--typography-body-xlarge-letter-spacing);
}

.bodyXLarge {
  font-weight: var(--typography-body-xlarge-font-weight);
  font-size: var(--typography-body-xlarge-font-size);
  line-height: var(--typography-body-xlarge-line-height);
  text-decoration: var(--typography-body-xlarge-text-decoration);
  font-style: var(--typography-body-xlarge-font-style);
  font-stretch: var(--typography-body-xlarge-font-stretch);
  letter-spacing: var(--typography-body-xlarge-letter-spacing);
}

.bodyLargeBold {
  font-weight: var(--typography-body-large-bold-font-weight);
  font-size: var(--typography-body-large-font-size);
  line-height: var(--typography-body-large-line-height);
  text-decoration: var(--typography-body-large-text-decoration);
  font-style: var(--typography-body-large-font-style);
  font-stretch: var(--typography-body-large-font-stretch);
  letter-spacing: var(--typography-body-large-letter-spacing);
}

.bodyLargeMedium {
  font-weight: var(--typography-body-large-medium-font-weight);
  font-size: var(--typography-body-large-font-size);
  line-height: var(--typography-body-large-line-height);
  text-decoration: var(--typography-body-large-text-decoration);
  font-style: var(--typography-body-large-font-style);
  font-stretch: var(--typography-body-large-font-stretch);
  letter-spacing: var(--typography-body-large-letter-spacing);
}

.bodyLarge {
  font-weight: var(--typography-body-large-font-weight);
  font-size: var(--typography-body-large-font-size);
  line-height: var(--typography-body-large-line-height);
  text-decoration: var(--typography-body-large-text-decoration);
  font-style: var(--typography-body-large-font-style);
  font-stretch: var(--typography-body-large-font-stretch);
  letter-spacing: var(--typography-body-large-letter-spacing);
}

.bodyRegularBold {
  font-weight: var(--typography-body-regular-bold-font-weight);
  font-size: var(--typography-body-regular-font-size);
  line-height: var(--typography-body-regular-line-height);
  text-decoration: var(--typography-body-regular-text-decoration);
  font-style: var(--typography-body-regular-font-style);
  font-stretch: var(--typography-body-regular-font-stretch);
  letter-spacing: var(--typography-body-regular-letter-spacing);
}

.bodyRegularMedium {
  font-weight: var(--typography-body-regular-medium-font-weight);
  font-size: var(--typography-body-regular-font-size);
  line-height: var(--typography-body-regular-line-height);
  text-decoration: var(--typography-body-regular-text-decoration);
  font-style: var(--typography-body-regular-font-style);
  font-stretch: var(--typography-body-regular-font-stretch);
  letter-spacing: var(--typography-body-regular-letter-spacing);
}

.bodyRegular {
  font-weight: var(--typography-body-regular-font-weight);
  font-size: var(--typography-body-regular-font-size);
  line-height: var(--typography-body-regular-line-height);
  text-decoration: var(--typography-body-regular-text-decoration);
  font-style: var(--typography-body-regular-font-style);
  font-stretch: var(--typography-body-regular-font-stretch);
  letter-spacing: var(--typography-body-regular-letter-spacing);
}

.bodyRegularExtraLineHeightBold {
  font-weight: var(--typography-body-regular-bold-font-weight);
  font-size: var(--typography-body-regular-font-size);
  line-height: var(--typography-body-regular-line-height-extra);
  text-decoration: var(--typography-body-regular-text-decoration);
  font-style: var(--typography-body-regular-font-style);
  font-stretch: var(--typography-body-regular-font-stretch);
  letter-spacing: var(--typography-body-regular-letter-spacing);
}

.bodyRegularExtraLineHeightMedium {
  font-weight: var(--typography-body-regular-medium-font-weight);
  font-size: var(--typography-body-regular-font-size);
  line-height: var(--typography-body-regular-line-height-extra);
  text-decoration: var(--typography-body-regular-text-decoration);
  font-style: var(--typography-body-regular-font-style);
  font-stretch: var(--typography-body-regular-font-stretch);
  letter-spacing: var(--typography-body-regular-letter-spacing);
}

.bodyRegularExtraLineHeight {
  font-weight: var(--typography-body-regular-font-weight);
  font-size: var(--typography-body-regular-font-size);
  line-height: var(--typography-body-regular-line-height-extra);
  text-decoration: var(--typography-body-regular-text-decoration);
  font-style: var(--typography-body-regular-font-style);
  font-stretch: var(--typography-body-regular-font-stretch);
  letter-spacing: var(--typography-body-regular-letter-spacing);
}

.bodySmallBold {
  font-weight: var(--typography-body-small-bold-font-weight);
  font-size: var(--typography-body-small-font-size);
  line-height: var(--typography-body-small-line-height);
  text-decoration: var(--typography-body-small-text-decoration);
  font-style: var(--typography-body-small-font-style);
  font-stretch: var(--typography-body-small-font-stretch);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.bodySmallMedium {
  font-weight: var(--typography-body-small-medium-font-weight);
  font-size: var(--typography-body-small-font-size);
  line-height: var(--typography-body-small-line-height);
  text-decoration: var(--typography-body-small-text-decoration);
  font-style: var(--typography-body-small-font-style);
  font-stretch: var(--typography-body-small-font-stretch);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.bodySmall {
  font-weight: var(--typography-body-small-font-weight);
  font-size: var(--typography-body-small-font-size);
  line-height: var(--typography-body-small-line-height);
  text-decoration: var(--typography-body-small-text-decoration);
  font-style: var(--typography-body-small-font-style);
  font-stretch: var(--typography-body-small-font-stretch);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.bodySmallExtraLineHeightBold {
  font-weight: var(--typography-body-small-bold-font-weight);
  font-size: var(--typography-body-small-font-size);
  line-height: var(--typography-body-small-line-height-extra);
  text-decoration: var(--typography-body-small-text-decoration);
  font-style: var(--typography-body-small-font-style);
  font-stretch: var(--typography-body-small-font-stretch);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.bodySmallExtraLineHeightMedium {
  font-weight: var(--typography-body-small-medium-font-weight);
  font-size: var(--typography-body-small-font-size);
  line-height: var(--typography-body-small-line-height-extra);
  text-decoration: var(--typography-body-small-text-decoration);
  font-style: var(--typography-body-small-font-style);
  font-stretch: var(--typography-body-small-font-stretch);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.bodySmallExtraLineHeight {
  font-weight: var(--typography-body-small-font-weight);
  font-size: var(--typography-body-small-font-size);
  line-height: var(--typography-body-small-line-height-extra);
  text-decoration: var(--typography-body-small-text-decoration);
  font-style: var(--typography-body-small-font-style);
  font-stretch: var(--typography-body-small-font-stretch);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.bodyXLargeBold,
.bodyXLargeMedium,
.bodyXLarge,
.bodyLargeBold,
.bodyLargeMedium,
.bodyLarge,
.bodyRegularBold,
.bodyRegularMedium,
.bodyRegular,
.bodyRegularExtraLineHeightBold,
.bodyRegularExtraLineHeightMedium,
.bodyRegularExtraLineHeight,
.bodySmallBold,
.bodySmallMedium,
.bodySmall,
.bodySmallExtraLineHeightBold,
.bodySmallExtraLineHeightMedium,
.bodySmallExtraLineHeight {
  font-family: var(--font-family-primary),
    var(--typography-body-fallback-font-stack);
}

.heading1 {
  font-size: var(--typography-heading-1-font-size);
  font-weight: var(--typography-heading-1-font-weight);
  line-height: var(--typography-heading-1-line-height);
  letter-spacing: var(--typography-heading-1-letter-spacing);
}

.heading2 {
  font-size: var(--typography-heading-2-font-size);
  font-weight: var(--typography-heading-2-font-weight);
  line-height: var(--typography-heading-2-line-height);
  letter-spacing: var(--typography-heading-2-letter-spacing);
}

.heading3 {
  font-size: var(--typography-heading-3-font-size);
  font-weight: var(--typography-heading-3-font-weight);
  line-height: var(--typography-heading-3-line-height);
  letter-spacing: var(--typography-heading-3-letter-spacing);
}

.heading4 {
  font-size: var(--typography-heading-4-font-size);
  font-weight: var(--typography-heading-4-font-weight);
  line-height: var(--typography-heading-4-line-height);
  letter-spacing: var(--typography-heading-4-letter-spacing);
}

.heading5 {
  font-size: var(--typography-heading-5-font-size);
  font-weight: var(--typography-heading-5-font-weight);
  line-height: var(--typography-heading-5-line-height);
  letter-spacing: var(--typography-heading-5-letter-spacing);
}

.heading6 {
  font-size: var(--typography-heading-6-font-size);
  font-weight: var(--typography-heading-6-font-weight);
  line-height: var(--typography-heading-6-line-height);
  letter-spacing: var(--typography-heading-6-letter-spacing);
}

.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6 {
  color: var(--color-typography-heading);
  font-family: var(--font-family-heading),
    var(--typography-body-fallback-font-stack);
}

@media (max-width: 767px) {
  .bodyXLargeBold {
    font-weight: var(--typography-body-large-bold-font-weight);
  }

  .bodyXLargeMedium {
    font-weight: var(--typography-body-large-medium-font-weight);
  }

  .bodyXLarge {
    font-weight: var(--typography-body-large-font-weight);
  }

  .bodyXLargeBold,
  .bodyXLargeMedium,
  .bodyXLarge {
    font-size: var(--typography-body-large-font-size);
    line-height: var(--typography-body-large-line-height);
    text-decoration: var(--typography-body-large-text-decoration);
    font-family: var(--typography-body-large-font-family),
      var(--typography-body-fallback-font-stack);
    font-style: var(--typography-body-large-font-style);
    font-stretch: var(--typography-body-large-font-stretch);
    letter-spacing: var(--typography-body-large-letter-spacing);
  }

  .heading1 {
    font-size: var(--typography-heading-2-font-size);
    font-weight: var(--typography-heading-2-font-weight);
    line-height: var(--typography-heading-2-line-height);
  }

  .heading2 {
    font-size: var(--typography-heading-3-font-size);
    font-weight: var(--typography-heading-3-font-weight);
    line-height: var(--typography-heading-3-line-height);
  }

  .heading3 {
    font-size: var(--typography-heading-4-font-size);
    font-weight: var(--typography-heading-4-font-weight);
    line-height: var(--typography-heading-4-line-height);
  }

  .heading4 {
    font-size: var(--typography-heading-5-font-size);
    font-weight: var(--typography-heading-5-font-weight);
    line-height: var(--typography-heading-5-line-height);
  }

  .heading5 {
    font-size: var(--typography-heading-6-font-size);
    font-weight: var(--typography-heading-6-font-weight);
    line-height: var(--typography-heading-6-line-height);
  }

  .heading6 {
    font-size: var(--typography-heading-6-font-size);
    font-weight: var(--typography-heading-6-font-weight);
    line-height: var(--typography-heading-6-line-height);
  }
}
