:root {
  --breadcrumbs-bg-color: transparent;
  --breadcrumbs-hover-bg-color: transparent;
  --breadcrumbs-icon-color: var(--primary-blue-200);

  /*
  * Breadcrumb text / link / background
  */
  --breadcrumbs-item-border-radius: 5px;
  --breadcrumbs-item-horizontal-padding: 8px;
  --breadcrumbs-item-color: var(--primary-blue-200);
  --breadcrumbs-item-color-active: var(--color-interactive-action-primary);
  --breadcrumbs-item-text-transform: var(--typography-caption-text-transform);
  --breadcrumbs-item-font-size: var(--typography-caption-font-size);
  --breadcrumbs-item-line-height: 16px;
  --breadcrumbs-item-text-decoration: var(--typography-caption-text-decoration);
  --breadcrumbs-item-text-decoration-hover: underline;
  --breadcrumbs-item-font-family: var(--typography-caption-font-family-stack);
  --breadcrumbs-item-font-weight: var(--typography-caption-font-weight);
  --breadcrumbs-item-font-weight-active: var(--typography-caption-font-weight);
  --breadcrumbs-item-letter-spacing: var(--typography-caption-letter-spacing);
  --breadcrumbs-item-icon-size: 16px;

  /*
  * Breadcrumb Spacing
  */
  --breadcrumbs-separator-color: var(--primary-blue-200);
  --breadcrumbs-separator-icon-size: 16px;
  --breadcrumbs-separator-horizontal-margin: 8px;
}
