:root {
  --checkbox-color: var(--color-text-primary);
  --checkbox-bg-color-disabled: var(--color-state-inactive);
  --checkbox-color-disabled: var(--color-text-secondary);
  --checkbox-bg-color-checked: var(--color-interactive-action-primary);
  --checkbox-border: 1px solid var(--color-border-line-primary);
  --checkbox-border-radius: 3px;

  /* small */
  --checkbox-width-sm: 16px;
  --checkbox-height-sm: 16px;
  --checkbox-padding-sm: 0;

  /* medium */
  --checkbox-width-md: 18px;
  --checkbox-height-md: 18px;
  --checkbox-padding-md: 2px;

  /* large */
  --checkbox-width-lg: 24px;
  --checkbox-height-lg: 24px;
  --checkbox-padding-lg: 4px;

  /* font */
  --checkbox-label-font-size: var(--typography-body-font-size);
  --checkbox-label-line-height: var(--typography-body-line-height);
  --checkbox-label-font-family: var(--typography-body-font-family-stack);
  --checkbox-label-letter-spacing: var(--typography-body-letter-spacing);
  --checkbox-label-margin: 0 0 5px 0;
  --checkbox-double-label-font-size: 12px;
  --checkbox-hint-font-size: var(--typography-body-caption-font-size);
  --checkbox-hint-color: var(--color-text-secondary);

  /* Boxed  */
  --checkbox-padding-boxed: 10px;
  --checkbox-content-max-width-boxed: 326px;
  --checkbox-content-padding-boxed: 0 5px;
  --checkbox-content-margin-boxed: 0 0 0 10px;
  --checkbox-border-radius-boxed: 0;

  /** Vertical Paddings **/
  --checkbox-vertical-padding: 7px 0;

  /** Horizontal Paddings **/
  --checkbox-horizontal-padding: 0 7px;
}
