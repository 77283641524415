:root {
  --primary-blue-100: #b6bec9;
  --primary-blue-200: #8692a6;
  --primary-blue-400: #243b5e;
  --primary-cyan-100: rgba(0, 159, 227, 10%);
  --primary-cyan-500: #009fe3;
  --primary-lime-100: #eaf3d2;
  --primary-lime-500: #7a9e19;
  --primary-orange-500: #f18805;
  --primary-purple-600: #951b81;
  --greyscale-50: #fafafa;
  --greyscale-100: #f5f5f5;
  --greyscale-200: #eee;
  --greyscale-300: #e0e0e0;
  --greyscale-400: #bdbdbd;
  --greyscale-500: #9e9e9e;
  --greyscale-600: #757575;
  --greyscale-700: #616161;
  --greyscale-900: #212121;
  --secondary-sale-red: #cf0b2a;
  --color-text-primary: var(--greyscale-900);
  --color-text-primary-blue: #0c254c;
  --color-text-secondary: #6b7177;
  --color-text-accent-01: #bdbfc3;
  --color-text-action-primary: #fff;
  --color-text-action-secondary: #42526e;
  --color-text-action-accent-01: #42526e;
  --color-text-action-link: var(--greyscale-900);
  --color-text-action-link-hover: #0065ff;
  --color-text-field-placeholder: #737373;
  --color-text-field-active: #232936;
  --color-text-editor: #f92672;
  --color-brand-primary: #232936;
  --color-brand-secondary: #f6625a;
  --color-brand-accent-01: #213550;
  --color-background-primary: #fff;
  --color-background-secondary: #f7f7f7;
  --color-background-gold: #f6c100;
  --color-background-product-thumb: #f4f6f9;
  --color-background-accent-01: #232936;
  --color-background-overlay: rgba(129, 129, 129, 50%);
  --color-background-category: #dcd7d4;
  --color-background-cart-banner: #ccecf9;
  --color-background-dropzone-active: #e7eaee;
  --color-background-editor: #282c34;
  --color-background-youtube: #14264b;
  --color-spinner-overlay: rgba(255, 255, 255, 70%);
  --color-background-radio-disabled: #e0e0e0;
  --color-radio-checked-disabled: #7a9e19;
  --color-background-checkbox-checked-disable: #7a9e19;
  --color-state-success: #95c11f;
  --color-state-success-light: #7a9e19;
  --color-state-pending: #ffff;
  --color-state-pending-light: #e5007d;
  --color-state-processing: #ccdcf5;
  --color-state-alert: #e20f14;
  --color-state-error: #e20f14;
  --color-state-critical: #d82c0d;
  --color-state-critical-light: #ffc3b8;
  --color-state-warning: #ffd483;
  --color-state-inactive: #f7f7f7;
  --color-state-discount: #df5641;
  --color-state-secondary: #c5006c;
  --color-state-info: #519dfc;
  --color-state-alert-info: #246bfd;
  --color-alert-status-error: #f75555;
  --color-alert-status-success: #4ade80;
  --color-interactive-action-primary: #0c254c;
  --color-interactive-action-primary-hover: #243b5e;
  --color-quantity-input-button-disabled: #0c254c7f;
  --color-interactive-action-secondary: #fff;
  --color-interactive-action-secondary-hover: #243b5e;
  --color-interactive-action-accent-01: #fff;
  --color-interactive-action-accent-01-hover: #f4f4f4;
  --color-interactive-action-field: #d9d9d9;
  --color-interactive-action-focus: #b6bec9;
  --color-border-line-primary: #e0e0e0;
  --color-border-line-seperate-primary: #eceff1;
  --color-border-line-seperate-secondary: #464e5f;
  --color-ecommerce-sale: #cf0b2a;
  --color-ecommerce-reviews: #feca00;
  --color-grayscale-black: #000;
  --color-grayscale-dark-grey: #3f4245;
  --color-grayscale-mid-grey: #919191;
  --color-grayscale-lightgrey: #f1f1f1;
  --color-grayscale-white: #fff;
  --color-grayscale-300: #e0e0e0;
  --color-tags-sale: #d82c0d;
  --color-slider-track: #7a9e19;
  --color-slider-remaining-track: rgba(224, 227, 234, 30%);
  --color-checkbox-stroke-line: #fff;
  --primary-blue-shadow: 0 2px 4px 0 #0c254c1f;
  --effect-shadow: 0 2px 6px 0 #00000040;
  --effect-card-elevation: 0 0 4px 0 #3333;
  --drag-handle-color: #919eab;
  --drag-handle-shadow: #4c9ffe;
  --notification-content-color: 'inherit';
  --notification-size-md: 395px;
  --typography-button-font-size: 14px;
  --typography-button-text-decoration: none;
  --typography-button-font-family: var(--font-family-primary);
  --typography-button-font-weight: 800;
  --typography-button-font-style: normal;
  --typography-button-font-stretch: normal;
  --typography-button-letter-spacing: 0.15px;
  --typography-button-line-height: 16px;
  --typography-button-paragraph-indent: 0;
  --typography-button-paragraph-spacing: 0;
  --typography-button-text-case: capitalize;
  --typography-label-font-size: 12px;
  --typography-label-text-decoration: none;
  --typography-label-font-family: var(--font-family-primary);
  --typography-label-font-weight: 500;
  --typography-label-font-style: normal;
  --typography-label-font-stretch: normal;
  --typography-label-letter-spacing: 2px;
  --typography-label-line-height: 14.4px;
  --typography-label-paragraph-indent: 0;
  --typography-label-paragraph-spacing: 0;
  --typography-label-text-case: uppercase;
  --typography-display-x-large-desktop-font-size: 34px;
  --typography-display-x-large-desktop-text-decoration: none;
  --typography-display-x-large-desktop-font-family: var(--font-family-primary);
  --typography-display-x-large-desktop-font-weight: 700;
  --typography-display-x-large-desktop-font-style: normal;
  --typography-display-x-large-desktop-font-stretch: normal;
  --typography-display-x-large-desktop-letter-spacing: 0;
  --typography-display-x-large-desktop-line-height: 36px;
  --typography-display-x-large-desktop-paragraph-indent: 0;
  --typography-display-x-large-desktop-paragraph-spacing: 0;
  --typography-display-x-large-desktop-text-case: none;
  --typography-display-x-large-mobile-font-size: 34px;
  --typography-display-x-large-mobile-text-decoration: none;
  --typography-display-x-large-mobile-font-family: var(--font-family-primary);
  --typography-display-x-large-mobile-font-weight: 700;
  --typography-display-x-large-mobile-font-style: normal;
  --typography-display-x-large-mobile-font-stretch: normal;
  --typography-display-x-large-mobile-letter-spacing: 0.1px;
  --typography-display-x-large-mobile-line-height: 36px;
  --typography-display-x-large-mobile-paragraph-indent: 0;
  --typography-display-x-large-mobile-paragraph-spacing: 0;
  --typography-display-x-large-mobile-text-case: none;
  --typography-display-large-desktop-font-size: 24px;
  --typography-display-large-desktop-text-decoration: none;
  --typography-display-large-desktop-font-family: var(--font-family-primary);
  --typography-display-large-desktop-font-weight: 700;
  --typography-display-large-desktop-font-style: normal;
  --typography-display-large-desktop-font-stretch: normal;
  --typography-display-large-desktop-letter-spacing: 0.1px;
  --typography-display-large-desktop-line-height: 26px;
  --typography-display-large-desktop-paragraph-indent: 0;
  --typography-display-large-desktop-paragraph-spacing: 0;
  --typography-display-large-desktop-text-case: none;
  --typography-display-large-mobile-font-size: 24px;
  --typography-display-large-mobile-text-decoration: none;
  --typography-display-large-mobile-font-family: var(--font-family-primary);
  --typography-display-large-mobile-font-weight: 700;
  --typography-display-large-mobile-font-style: normal;
  --typography-display-large-mobile-font-stretch: normal;
  --typography-display-large-mobile-letter-spacing: 0.1px;
  --typography-display-large-mobile-line-height: 26px;
  --typography-display-large-mobile-paragraph-indent: 0;
  --typography-display-large-mobile-paragraph-spacing: 0;
  --typography-display-large-mobile-text-case: none;
  --typography-display-medium-desktop-font-size: 20px;
  --typography-display-medium-desktop-text-decoration: none;
  --typography-display-medium-desktop-font-family: helvetica;
  --typography-display-medium-desktop-font-weight: 700;
  --typography-display-medium-desktop-font-style: normal;
  --typography-display-medium-desktop-font-stretch: normal;
  --typography-display-medium-desktop-letter-spacing: 0.1px;
  --typography-display-medium-desktop-line-height: 24px;
  --typography-display-medium-desktop-paragraph-indent: 0;
  --typography-display-medium-desktop-paragraph-spacing: 0;
  --typography-display-medium-desktop-text-case: none;
  --typography-display-medium-mobile-font-size: 20px;
  --typography-display-medium-mobile-text-decoration: none;
  --typography-display-medium-mobile-font-family: var(--font-family-primary);
  --typography-display-medium-mobile-font-weight: 700;
  --typography-display-medium-mobile-font-style: normal;
  --typography-display-medium-mobile-font-stretch: normal;
  --typography-display-medium-mobile-letter-spacing: 0.1px;
  --typography-display-medium-mobile-line-height: 24px;
  --typography-display-medium-mobile-paragraph-indent: 0;
  --typography-display-medium-mobile-paragraph-spacing: 0;
  --typography-display-medium-mobile-text-case: none;
  --typography-display-small-desktop-font-size: 14px;
  --typography-display-small-desktop-text-decoration: none;
  --typography-display-small-desktop-font-family: var(--font-family-primary);
  --typography-display-small-desktop-font-weight: 700;
  --typography-display-small-desktop-font-style: normal;
  --typography-display-small-desktop-font-stretch: normal;
  --typography-display-small-desktop-letter-spacing: 5px;
  --typography-display-small-desktop-line-height: 16.8px;
  --typography-display-small-desktop-paragraph-indent: 0;
  --typography-display-small-desktop-paragraph-spacing: 0;
  --typography-display-small-desktop-text-case: uppercase;
  --typography-display-small-mobile-font-size: 14px;
  --typography-display-small-mobile-text-decoration: none;
  --typography-display-small-mobile-font-family: var(--font-family-primary);
  --typography-display-small-mobile-font-weight: 700;
  --typography-display-small-mobile-font-style: normal;
  --typography-display-small-mobile-font-stretch: normal;
  --typography-display-small-mobile-letter-spacing: 5px;
  --typography-display-small-mobile-line-height: 16.8px;
  --typography-display-small-mobile-paragraph-indent: 0;
  --typography-display-small-mobile-paragraph-spacing: 0;
  --typography-display-small-mobile-text-case: uppercase;
  --typography-display-xs-small-desktop-font-size: 16px;
  --typography-display-xs-small-desktop-text-decoration: none;
  --typography-display-xs-small-desktop-font-family: var(--font-family-primary);
  --typography-display-xs-small-desktop-font-weight: 500;
  --typography-display-xs-small-desktop-font-style: normal;
  --typography-display-xs-small-desktop-font-stretch: normal;
  --typography-display-xs-small-desktop-letter-spacing: 0;
  --typography-display-xs-small-desktop-line-height: 19.2px;
  --typography-display-xs-small-desktop-paragraph-indent: 0;
  --typography-display-xs-small-desktop-paragraph-spacing: 0;
  --typography-display-xs-small-desktop-text-case: none;
  --typography-display-xs-small-mobile-font-size: 14px;
  --typography-display-xs-small-mobile-text-decoration: none;
  --typography-display-xs-small-mobile-font-family: var(--font-family-primary);
  --typography-display-xs-small-mobile-font-weight: 500;
  --typography-display-xs-small-mobile-font-style: normal;
  --typography-display-xs-small-mobile-font-stretch: normal;
  --typography-display-xs-small-mobile-letter-spacing: 0;
  --typography-display-xs-small-mobile-line-height: 16.8px;
  --typography-display-xs-small-mobile-paragraph-indent: 0;
  --typography-display-xs-small-mobile-paragraph-spacing: 0;
  --typography-display-xs-small-mobile-text-case: none;
  --typography-page-heading-desktop-font-size: 60px;
  --typography-page-heading-desktop-text-decoration: none;
  --typography-page-heading-desktop-font-family: var(--font-family-primary);
  --typography-page-heading-desktop-font-weight: 700;
  --typography-page-heading-desktop-font-style: normal;
  --typography-page-heading-desktop-font-stretch: normal;
  --typography-page-heading-desktop-letter-spacing: 0;
  --typography-page-heading-desktop-line-height: 72px;
  --typography-page-heading-desktop-paragraph-indent: 0;
  --typography-page-heading-desktop-paragraph-spacing: 0;
  --typography-page-heading-desktop-text-case: none;
  --typography-page-heading-mobile-font-size: 60px;
  --typography-page-heading-mobile-text-decoration: none;
  --typography-page-heading-mobile-font-family: var(--font-family-primary);
  --typography-page-heading-mobile-font-weight: 700;
  --typography-page-heading-mobile-font-style: normal;
  --typography-page-heading-mobile-font-stretch: normal;
  --typography-page-heading-mobile-letter-spacing: 0;
  --typography-page-heading-mobile-line-height: 72px;
  --typography-page-heading-mobile-paragraph-indent: 0;
  --typography-page-heading-mobile-paragraph-spacing: 0;
  --typography-page-heading-mobile-text-case: none;
  --typography-heading-desktop-font-size: 48px;
  --typography-heading-desktop-text-decoration: none;
  --typography-heading-desktop-font-family: var(--font-family-primary);
  --typography-heading-desktop-font-weight: 700;
  --typography-heading-desktop-font-style: normal;
  --typography-heading-desktop-font-stretch: normal;
  --typography-heading-desktop-letter-spacing: 0;
  --typography-heading-desktop-line-height: 56px;
  --typography-heading-desktop-paragraph-indent: 0;
  --typography-heading-desktop-paragraph-spacing: 0;
  --typography-heading-desktop-text-case: none;
  --typography-heading-mobile-font-size: 48px;
  --typography-heading-mobile-text-decoration: none;
  --typography-heading-mobile-font-family: var(--font-family-primary);
  --typography-heading-mobile-font-weight: 700;
  --typography-heading-mobile-font-style: normal;
  --typography-heading-mobile-font-stretch: normal;
  --typography-heading-mobile-letter-spacing: 0;
  --typography-heading-mobile-line-height: 56px;
  --typography-heading-mobile-paragraph-indent: 0;
  --typography-heading-mobile-paragraph-spacing: 0;
  --typography-heading-mobile-text-case: none;
  --typography-subheading-regular-desktop-font-size: 18px;
  --typography-subheading-regular-desktop-text-decoration: none;
  --typography-subheading-regular-desktop-font-family: var(
    --font-family-primary
  );
  --typography-subheading-regular-desktop-font-weight: 400;
  --typography-subheading-regular-desktop-font-style: normal;
  --typography-subheading-regular-desktop-font-stretch: normal;
  --typography-subheading-regular-desktop-letter-spacing: 0;
  --typography-subheading-regular-desktop-line-height: 21.6px;
  --typography-subheading-regular-desktop-paragraph-indent: 0;
  --typography-subheading-regular-desktop-paragraph-spacing: 0;
  --typography-subheading-regular-desktop-text-case: none;
  --typography-subheading-heavy-desktop-font-size: 18px;
  --typography-subheading-heavy-desktop-text-decoration: none;
  --typography-subheading-heavy-desktop-font-family: var(--font-family-primary);
  --typography-subheading-heavy-desktop-font-weight: 500;
  --typography-subheading-heavy-desktop-font-style: normal;
  --typography-subheading-heavy-desktop-font-stretch: normal;
  --typography-subheading-heavy-desktop-letter-spacing: 0;
  --typography-subheading-heavy-desktop-line-height: 21.6px;
  --typography-subheading-heavy-desktop-paragraph-indent: 0;
  --typography-subheading-heavy-desktop-paragraph-spacing: 0;
  --typography-subheading-heavy-desktop-text-case: none;
  --typography-subheading-regular-mobile-font-size: 14px;
  --typography-subheading-regular-mobile-text-decoration: none;
  --typography-subheading-regular-mobile-font-family: var(
    --font-family-primary
  );
  --typography-subheading-regular-mobile-font-weight: 400;
  --typography-subheading-regular-mobile-font-style: normal;
  --typography-subheading-regular-mobile-font-stretch: normal;
  --typography-subheading-regular-mobile-letter-spacing: 0;
  --typography-subheading-regular-mobile-line-height: 16.8px;
  --typography-subheading-regular-mobile-paragraph-indent: 0;
  --typography-subheading-regular-mobile-paragraph-spacing: 0;
  --typography-subheading-regular-mobile-text-case: none;
  --typography-subheading-heavy-mobile-font-size: 14px;
  --typography-subheading-heavy-mobile-text-decoration: none;
  --typography-subheading-heavy-mobile-font-family: var(--font-family-primary);
  --typography-subheading-heavy-mobile-font-weight: 500;
  --typography-subheading-heavy-mobile-font-style: normal;
  --typography-subheading-heavy-mobile-font-stretch: normal;
  --typography-subheading-heavy-mobile-letter-spacing: 0;
  --typography-subheading-heavy-mobile-line-height: 16.8px;
  --typography-subheading-heavy-mobile-paragraph-indent: 0;
  --typography-subheading-heavy-mobile-paragraph-spacing: 0;
  --typography-subheading-heavy-mobile-text-case: none;
  --typography-body-large-regular-font-size: 16px;
  --typography-body-large-regular-text-decoration: none;
  --typography-body-large-regular-font-family: var(--font-family-primary);
  --typography-body-large-regular-font-weight: 400;
  --typography-body-large-regular-font-style: normal;
  --typography-body-large-regular-font-stretch: normal;
  --typography-body-large-regular-letter-spacing: 0;
  --typography-body-large-regular-line-height: 19.2px;
  --typography-body-large-regular-paragraph-indent: 0;
  --typography-body-large-regular-paragraph-spacing: 0;
  --typography-body-large-regular-text-case: none;
  --typography-body-large-bold-font-size: 16px;
  --typography-body-large-bold-text-decoration: none;
  --typography-body-large-bold-font-family: var(--font-family-primary);
  --typography-body-large-bold-font-style: normal;
  --typography-body-large-bold-font-stretch: normal;
  --typography-body-large-bold-letter-spacing: 0;
  --typography-body-large-bold-line-height: 19.2px;
  --typography-body-large-bold-paragraph-indent: 0;
  --typography-body-large-bold-paragraph-spacing: 0;
  --typography-body-large-bold-text-case: none;
  --typography-body-regular-bold-font-size: 14px;
  --typography-body-regular-bold-text-decoration: none;
  --typography-body-regular-bold-font-family: var(--font-family-primary);
  --typography-body-regular-bold-font-style: normal;
  --typography-body-regular-bold-font-stretch: normal;
  --typography-body-regular-bold-letter-spacing: 0.07px;
  --typography-body-regular-bold-line-height: 16.8px;
  --typography-body-regular-bold-paragraph-indent: 0;
  --typography-body-regular-bold-paragraph-spacing: 0;
  --typography-body-regular-bold-text-case: none;
  --typography-body-medium-font-size: 16px;
  --typography-body-medium-text-decoration: none;
  --typography-body-medium-font-family: var(--font-family-primary);
  --typography-body-medium-font-weight: 400;
  --typography-body-medium-font-style: normal;
  --typography-body-medium-font-stretch: normal;
  --typography-body-medium-letter-spacing: 0.08px;
  --typography-body-medium-line-height: 19.2px;
  --typography-body-medium-paragraph-indent: 0;
  --typography-body-medium-paragraph-spacing: 0;
  --typography-body-medium-text-case: none;
  --typography-body-medium-bold-font-size: 16px;
  --typography-body-medium-bold-text-decoration: none;
  --typography-body-medium-bold-font-family: var(--font-family-primary);
  --typography-body-medium-bold-font-weight: 700;
  --typography-body-medium-bold-font-style: normal;
  --typography-body-medium-bold-font-stretch: normal;
  --typography-body-medium-bold-letter-spacing: 0.08px;
  --typography-body-medium-bold-line-height: 19.2px;
  --typography-body-medium-bold-paragraph-indent: 0;
  --typography-body-medium-bold-paragraph-spacing: 0;
  --typography-body-medium-bold-text-case: none;
  --typography-body-caption-font-size: 12px;
  --typography-body-caption-text-decoration: none;
  --typography-body-caption-font-family: var(--font-family-primary);
  --typography-body-caption-font-weight: 400;
  --typography-body-caption-font-style: normal;
  --typography-body-caption-font-stretch: normal;
  --typography-body-caption-letter-spacing: 0;
  --typography-body-caption-line-height: 14.4px;
  --typography-body-caption-paragraph-indent: 0;
  --typography-body-caption-paragraph-spacing: 0;
  --typography-body-caption-text-case: none;
  --typography-body-regular-underlined-font-size: 14px;
  --typography-body-regular-underlined-text-decoration: underline;
  --typography-body-regular-underlined-font-family: var(--font-family-primary);
  --typography-body-regular-underlined-font-weight: 400;
  --typography-body-regular-underlined-font-style: normal;
  --typography-body-regular-underlined-font-stretch: normal;
  --typography-body-regular-underlined-letter-spacing: 0.07px;
  --typography-body-regular-underlined-line-height: 16.8px;
  --typography-body-regular-underlined-paragraph-indent: 0;
  --typography-body-regular-underlined-paragraph-spacing: 0;
  --typography-body-regular-underlined-text-case: none;

  // Heading 1
  --typography-heading-1-font-size: 60px;
  --typography-heading-1-font-weight: 800;
  --typography-heading-1-line-height: 72px;
  // --typography-heading-1-letter-spacing: normal;
  // Heading 2
  --typography-heading-2-font-size: 48px;
  --typography-heading-2-font-weight: 800;
  --typography-heading-2-line-height: 56px;
  --typography-heading-2-letter-spacing: normal;
  // Heading 3
  --typography-heading-3-font-size: 40px;
  --typography-heading-3-font-weight: 800;
  --typography-heading-3-line-height: 48px;
  --typography-heading-3-letter-spacing: normal;
  // Heading 4
  --typography-heading-4-font-size: 34px;
  --typography-heading-4-font-weight: 800;
  --typography-heading-4-line-height: 36px;
  --typography-heading-4-letter-spacing: normal;
  // Heading 5
  --typography-heading-5-font-size: 24px;
  --typography-heading-5-font-weight: 800;
  --typography-heading-5-line-height: 26px;
  --typography-heading-5-letter-spacing: normal;
  // Heading 6
  --typography-heading-6-font-size: 20px;
  --typography-heading-6-font-weight: 800;
  --typography-heading-6-line-height: 24px;
  --typography-heading-6-letter-spacing: normal;

  // bodyXLarge
  --typography-body-xlarge-font-size: 24px;
  --typography-body-xlarge-text-decoration: none;
  --typography-body-xlarge-font-family: var(--font-family-primary);
  --typography-body-xlarge-bold-font-weight: 800;
  --typography-body-xlarge-medium-font-weight: 500;
  --typography-body-xlarge-font-weight: 400;
  --typography-body-xlarge-font-style: normal;
  --typography-body-xlarge-font-stretch: normal;
  --typography-body-xlarge-letter-spacing: 0.15px;
  --typography-body-xlarge-line-height: 32px;
  --typography-body-xlarge-paragraph-indent: 0;
  --typography-body-xlarge-paragraph-spacing: 0;
  --typography-body-xlarge-text-case: none;

  // bodyLarge
  --typography-body-large-font-size: 18px;
  --typography-body-large-text-decoration: none;
  --typography-body-large-font-family: var(--font-family-primary);
  --typography-body-large-bold-font-weight: 800;
  --typography-body-large-medium-font-weight: 500;
  --typography-body-large-font-weight: 400;
  --typography-body-large-font-style: normal;
  --typography-body-large-font-stretch: normal;
  --typography-body-large-letter-spacing: 0.15px;
  --typography-body-large-line-height: 24px;
  --typography-body-large-paragraph-indent: 0;
  --typography-body-large-paragraph-spacing: 0;
  --typography-body-large-text-case: none;

  //bodyregular
  --typography-body-regular-font-size: 14px;
  --typography-body-regular-text-decoration: none;
  --typography-body-regular-font-family: var(--font-family-primary);
  --typography-body-regular-bold-font-weight: 800;
  --typography-body-regular-medium-font-weight: 500;
  --typography-body-regular-font-weight: 400;
  --typography-body-regular-font-style: normal;
  --typography-body-regular-font-stretch: normal;
  --typography-body-regular-letter-spacing: 0.15px;
  --typography-body-regular-line-height: 16px;
  --typography-body-regular-line-height-extra: 24px;
  --typography-body-regular-paragraph-indent: 0;
  --typography-body-regular-paragraph-spacing: 0;
  --typography-body-regular-text-case: none;

  //bodySmall
  --typography-body-small-font-size: 12px;
  --typography-body-small-text-decoration: none;
  --typography-body-small-font-family: var(--font-family-primary);
  --typography-body-small-bold-font-weight: 800;
  --typography-body-small-medium-font-weight: 500;
  --typography-body-small-font-weight: 400;
  --typography-body-small-font-style: normal;
  --typography-body-small-font-stretch: normal;
  --typography-body-small-letter-spacing: 0.15px;
  --typography-body-small-line-height: 16px;
  --typography-body-small-line-height-extra: 20px;
  --typography-body-small-paragraph-indent: 0;
  --typography-body-small-paragraph-spacing: 0;
  --typography-body-small-text-case: none;
  --typography-subheading-font-size: 40px;
  --typography-subheading-line-height: 48px;
  --typography-subheading-font-weight: 700;
  --typography-subheading-font-weight-bold: 700;
  --typography-large-font-weight: 700;
  --input-placeholder-color: #757575;
  --input-stroke-width: 4;
  --btn-icon-size: 10px;
  --checkbox-content-margin: 0 0 0 10px;

  // Input
  --input-label-color: var(--color-text-primary-blue);
  --input-border-color: var(var(--greyscale-300));
  --input-border-color-focus: var(--color-interactive-action-primary);
  --input-placeholder-color-focus: var(--greyscale-300);
  --input-text-color: var(--color-interactive-action-primary);
  --input-label-color-disabled: var(--color-interactive-action-primary);
  --input-bg-color-disabled: var(--greyscale-300);
  --input-border-color-disabled: var(--greyscale-600);
  --input-text-color-disabled: var(--greyscale-600);
  --input-color-text_disabled: var(--greyscale-600);
  --label-font-size: 12px;
  --label-line-height: 16px;
  --input-padding-default: 0 8px;
  --input-color-placeholder: var(--greyscale-600);

  // Button
  --btn-loading-spinner-color: var(--greyscale-600);
  --btn-focus-outline-offset: 0;
  --btn-focus-outline-width: 2px;
  --btn-primary-disabled: var(--greyscale-300);
  --btn-primary-text-disabled: var(--greyscale-600);
  --btn-ghost-text-decoration: initial;
  --btn-ghost-color-text-disabled: var(--btn-primary-text-disabled);
  --btn-ghost-color-bg-disabled: var(--greyscale-300);
  --btn-ghost-color-border-disabled: var(--greyscale-300);
  --btn-solid-color-bg-secondary-hover: var(--greyscale-100);
  --btn-size-default-border-width: 1px;
  --btn-solid-color-text-secondary-hover: var(--primary-blue-400);
  --btn-solid-color-border-secondary-disabled: var(--greyscale-300);
  --btn-solid-color-border-secondary: var(--color-interactive-action-primary);
  --btn-solid-color-text-secondary: var(--color-text-primary-blue);
  --btn-secondary-focus: var(--color-interactive-action-focus);
  --btn-secondary-color-bg-disabled: var(--greyscale-300);
  --btn-secondary-color-text-disabled: var(--greyscale-600);
  --btn-icon-disabled-opacity: 1;
  --btn-icon-color-bg-primary: var(--color-interactive-action-primary);
  --btn-icon-color-text-primary: var(--color-text-action-primary);
  --btn-icon-color-bg-primary-hover: var(--primary-blue-400);
  --btn-icon-color-border-primary-hover: var(--primary-blue-400);
  --btn-icon-color-outline-primary-focus: var(--color-interactive-action-focus);
  --btn-icon-color-bg-disabled: var(--btn-primary-disabled);
  --btn-size-md-padding: 0 16px;
  --btn-size-md-height: 44px;
  --btn-font-weight: 800;
  --btn-link-color: var(--greyscale-900);
  --color-progress-bar: var(--primary-cyan-500);

  // Range Slider
  --color-slider-thumb: var(--color-background-product-thumb);

  // Typography
  --color-typography-heading: var(--color-text-primary-blue);
  --color-typography-body: var(--greyscale-900);

  //Select
  --select-option-bg-color-hover: #f7f7f7;
  --select-option-bg-color-selected: #f7f7f7;
  --select-option-bg-color-disabled: #f7f7f7;
  --select-option-text-color-default: #000;
  --select-option-text-color-hover-selected: #232936;
  --select-option-text-color-disabled: #737373;

  // Header
  --header-color-bg: var(--color-text-primary-blue);
  --header-color-border: var(--greyscale-300);
  --header-color-separator: var(--color-text-primary-blue);
  --header-mobile-color-separator: var(--primary-blue-400);

  // BenefitCards
  --benefit-cards-color-title: var(--color-text-primary-blue);
  --benefit-cards-color-description: var(--greyscale-900);

  // Search Results
  --search-results-color-muted: var(--greyscale-700);
  --search-results-color-border: var(--greyscale-300);

  // Product Card
  --product-card-color-bg-tag: var(--primary-lime-500);

  // New Colors
  --color-primary-muted: var(--greyscale-700);
  --color-secondary-muted: var(--greyscale-900);
  --color-border-primary: var(--greyscale-300);
  --color-background-primary-muted: var(--color-text-primary-blue);
  --color-state-success-alt: var(--color-state-success-light);

  // Navigation Card
  --navigation-card-color-icon: var(--primary-cyan-500);
  --navigation-card-color-description: var(--greyscale-700);

  // Footer
  --footer-bg-color: var(--color-text-primary-blue);
  --footer-call-centre-list-gap: 16px;
  --footer-social-media-platform-padding-bottom: 32px;
  --footer-social-media-platform-padding-top: 32px;
  --footer-social-media-platform-padding-top-lg: 16px;
  --footer-links-gap: 0;
  --footer-links-list-gap: 16px;
  --footer-links-padding-top: 40px;

  // Authentication Drawer
  --authentication-drawer-color-step-inactive: var(--primary-blue-200);
  --authentication-drawer-color-step-success: var(--color-state-success-light);
  --authentication-drawer-color-validation-success: var(
    --color-state-success-light
  );
  --authentication-drawer-color-validation-base: var(--greyscale-500);

  // TextAndImage
  --text-and-image-color-description: var(--primary-blue-400);

  // Store
  --color-store-btn: var(--color-text-primary-blue);
  --color-store-status: var(--color-state-success-light);

  // Product Main
  --quantity-selector-color-button: var(--color-text-primary-blue);
  --product-status-tooltip-color: var(--primary-lime-500);
  --card-color-bg-secondary: rgba(229, 0, 125, 10%);
  --card-color-border-secondary: var(--color-state-secondary);

  // Accordion
  --accordion-title-horizontal-padding: 15px;
  --accordion-title-vertical-padding: 10px;
  --accordion-icon-size: 18px;
  --accordion-title-color-disabled: #6b7177;

  // Product Comparison
  --product-comparison-color-bg-table: var(--greyscale-200);
  --product-comparison-color-btn: var(--color-text-primary-blue);
  --product-comparison-color-bg-btn: var(--color-grayscale-white);
  --status-color-bg-success: #7a9e19;
  --switch-color-bg-off: var(--greyscale-400);
  --switch-color-bg-on: #7a9e19;

  // Product Details
  --product-details-color-bg-cell: #b6bec933;

  // Breadcrumbs
  --breadcrumbs-color: var(--primary-blue-400);
  --breadcrumbs-color-icon: var(--primary-blue-200);

  // CardsCarousel
  --cards-carousel-color-title: var(--color-text-primary-blue);

  // PromoBanner
  --promobanner-btn-bg-color: #638015;

  // OrderDeliveryCard
  --order-delivery-card-color-summary: var(--greyscale-500);
  --order-delivery-card-color-step-inactive: var(--greyscale-400);

  // ListDetails
  --list-details-color-bg-summary: var(--greyscale-100);

  // CustomerLists
  --customer-lists-color-bg-warning: #facc15;
  --customer-lists-color-border-warning: #facc15;

  // ListCard
  --list-card-color--text-date-time: var(--greyscale-900);
  --list-card-font-weight-date-time: var(
    --typography-body-small-bold-font-weight
  );
  --list-card-color-text-product-count: var(--greyscale-900);
  --list-card-font-weight-product-count: var(
    --typography-body-regular-bold-font-weight
  );

  // OrderConfirmation
  --order-confirmation-color-bg-top: #f4f6f9;

  // FourZeroFour
  --fourzerofour-content-margin-top: 112px;
  --fourzerofour-sm-content-margin-top: 288px;
  --fourzerofour-border-color: #fafafa;
  --fourzerofour-para-color: #fff;

  // Cart Drawer
  --cart-upsell-slider-color-bg: var(--greyscale-50);

  // CartItem
  --cart-item-color-warning-text: var(--color-state-warning);

  // CustomerSavedAddress
  --customer-saved-address-color-text: var(--greyscale-900);
  --customer-saved-address-color-bg-tag-other: #eee;
  --customer-saved-address-color-text-tag-other: #9e9e9e;
  --customer-saved-address-color-bg-tag-business-home: #ccecf9;
  --customer-saved-address-color-text-tag-business-home: #009fe3;

  // CreateCustomerSavedAddressCard
  --create-customer-saved-address-color-icon: #bdbdbd;

  // CategoryCard
  --category-card-link-border-color: transparent;

  // MegaMenu
  --mega-menu-color-link-primary: var(--color-grayscale-white);
  --mega-menu-color-link-secondary: var(--greyscale-700);
  --mega-menu-color-link-muted: var(--primary-blue-200);
  --mega-menu-color-border-primary: var(--primary-blue-400);

  // AccountOrderReturn
  --account-order-return-select-btn-color: var(--greyscale-600);

  //CategoryTile
  --category-tile-title-color: var(--color-text-primary-blue);

  //CompareProductsSnackbar
  --compare-product-fillBtn-color: #fff;
  --compare-product-outlineBtn-color: var(--color-text-primary-blue);

  //FilterItem
  --filter-item-suffix-color: var(--color-text-primary-blue);

  //OrderDeliveryDetailsCard
  --progress-text-color-disabled: var(--greyscale-400);

  //ProductListFilters
  --product-list-filters-color-separator: var(--color-text-primary-blue);

  // VideoCarousel
  --video-carousel-color-bg-btn: var(--color-text-primary-blue);
  --video-carousel-color-bg-btn-hover: var(--primary-blue-400);
  --video-carousel-color-btn: var(--color-grayscale-white);
}
