:root {
  --badge-border-radius: 4px;
  --badge-border-radius-rounded: 25px;
  --badge-text-padding: 7px 12px;
  --badge-text-padding-rounded: 3px 13px;
  --badge-text-min-height: 27px;
  --badge-text-font-size: var(--typography-caption-font-size);
  --badge-text-font-weight: var(--typography-caption-font-weight);
  --badge-text-color-default: var(--color-grayscale-white);
  --badge-text-line-height: var(--typography-caption-line-height);
  --badge-text-font-family: var(--typography-caption-font-family-stack);
  --badge-text-letter-spacing: var(--typography-caption-letter-spacing);

  /** Number Vars **/
  --badge-number-padding: 0 7px;
  --badge-number-min-height: 20px;
  --badge-number-font-size: var(--typography-caption-font-size);
  --badge-number-font-weight: var(--typography-caption-font-weight);
  --badge-number-color: var(--color-grayscale-white);
  --badge-number-line-height: var(--typography-caption-line-height);
  --badge-number-font-family: var(--typography-caption-font-family-stack);

  /** Dot **/
  --badge-dot-default-bg-color: var(--color-state-success);
  --badge-dot-width: 6px;
  --badge-dot-box-shadow: var(--shadow-100);
  --badge-dot-font-size: 12px;
  --badge-dot-text-margin: 0 0 0 10px;
  --badge-dot-text-color: var(--color-text-primary);
  --badge-pending-bg-color: var(--color-state-pending);
  --badge-success-bg-color: var(--color-state-success-light);
  --badge-warning-bg-color: var(--color-state-warning);
  --badge-critical-bg-color: var(--color-state-discount);
  --badge-cancled-bg-color: var(--color-state-critical-light);
  --badge-new-bg-color: var(--color-background-accent-01);
  --badge-bestseller-bg-color: #213550;
}
