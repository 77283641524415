@layer components {
  .sf-list-item {
    @apply border-b border-neutral-200 text-gray-900 capitalize md:rounded-md active:bg-gray-200;

    &-sm {
      @apply text-sm px-4 py-1 border-b-0;
    }

    > span {
      @apply flex;
    }
  }

  .sf-chip {
    &-sm {
      @apply text-sm py-1.5 gap-1.5;
    }
  }

  .sf-thumbnail {
    @apply bg-[var(--color)] bg-[url:var(--color)];
  }

  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden /* for Chrome, Safari, and Opera */;
    }
  }

  .card-stack {
    .card:not(:first-child) {
      border-top-width: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .card:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
