:root {
  /* Global */
  --switch-size-default: 24px;
  --switch-size-small: 16px;
  --switch-font-size-text: 12px;

  /* circle and Square */
  --switch-size-dot-default: 16px;
  --switch-size-dot-small: 12px;

  /* line */
  --switch-line-size-dot-default: 20px;
  --switch-line-size-dot-small: 16px;

  /* Circle */
  --switch-circle-default-width: 40px;
  --switch-circle-small-width: 28px;

  /* Square */
  --switch-square-default-width: 40px;
  --switch-square-small-width: 28px;

  /* Line */
  --switch-line-default-width: 36px;
  --switch-line-small-width: 28px;
  --switch-line-height-bg-line: 6px;
  --switch-line-color-dot-shadow: var(--color-grayscale-mid-grey);
  --switch-color-bg_on: var(--color-state-success);
  --switch-color-bg_off: var(--color-interactive-action-secondary);
  --switch-color-bg_on_disabled: var(--color-state-success-light);
  --switch-color-bg_off_disabled: var(--color-interactive-action-secondary);
  --switch-color-bg_on_loading: var(--color-state-success-light);
  --switch-color-bg_off_loading: var(--color-state-inactive);
  --switch-color-dot-bg: var(--color-grayscale-white);
  --switch-color-text_on: var(--color-grayscale-white);
  --switch-color-text_off: var(--color-grayscale-white);
  --switch-color-text_on_disabled: var(--color-grayscale-white);
  --switch-color-text_off_disabled: var(--color-grayscale-white);
  --switch-color-text_on_loading: var(--color-grayscale-light-grey);
  --switch-color-text_off_loading: var(--color-grayscale-white);
  --switch-color-dot-icon_on: var(--color-state-success);
  --switch-color-dot-icon_off: var(--color-grayscale-mid-grey);
  --switch-color-dot-icon_on_disabled: var(--color-state-success-light);
  --switch-color-dot-icon_off_disabled: var(--color-state-inactive);
  --switch-color-dot-icon_on_loading: var(--color-state-success-light);
  --switch-color-dot-icon_off_loading: var(--color-state-inactive);
  --switch-loader: anim-rotate 2s infinite linear;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
