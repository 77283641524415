:root {
  --spacing-2px: 2px;
  --spacing-4px: 4px;
  --spacing-6px: 6px;
  --spacing-8px: 8px;
  --spacing-12px: 12px;
  --spacing-16px: 16px;
  --spacing-20px: 20px;
  --spacing-24px: 24px;
  --spacing-28px: 28px;
  --spacing-32px: 32px;
  --spacing-40px: 40px;
  --spacing-48px: 48px;
  --spacing-64px: 64px;
  --spacing-80px: 80px;
  --section-spacing-default: var(--spacing-16px);
}
