:root {
  --notification-bg-color: var(--color-background-primary);
  --notification-text-color: var(--color-text-primary);
  --notification-box-shadow: var(--shadow-100);
  --notification-min-height: 56px;
  --notification-title-font-family: var(--typography-body-font-family-stack);
  --notification-title-font-size: var(--typography-body-font-size);
  --notification-title-font-weight: var(--typography-body-font-weight);
  --notification-title-line-height: var(--typography-body-line-height);
  --notification-title-letter-spacing: var(--typography-body-letter-spacing);
  --notification-title-color: var(--color-text-primary);

  /** Content **/
  --notification-content-font-family: var(--typography-body-font-family-stack);
  --notification-content-font-size: var(--typography-body-font-size);
  --notification-content-font-weight: var(--typography-body-font-weight);
  --notification-content-line-height: var(--typography-body-line-height);
  --notification-content-letter-spacing: var(--typography-body-letter-spacing);
  --notification-content-color: var(--color-text-secondary);
  --notification-error-text-color: var(--color-state-critical);
  --notification-error-border-color: var(--color-state-critical);
  --notification-error-bg-color: var(--color-background-primary);
  --notification-success-text-color: var(--color-text-secondary);
  --notification-success-border-color: var(--color-state-success);
  --notification-success-bg-color: var(--color-background-primary);
  --notification-notice-text-color: var(--color-text-secondary);
  --notification-notice-border-color: var(--color-state-notice);
  --notification-notice-bg-color: var(--color-background-primary);
  --notification-warning-text-color: var(--color-background-primary);
  --notification-warning-border-color: var(--color-state-warning);
  --notification-warning-bg-color: var(--color-background-primary);

  /** Sizes **/
  --notification-size-sm: 240px;
  --notification-size-md: 320px;
  --notification-size-lg: 420px;
}
