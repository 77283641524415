:root:has(.theme-rtm),
:root.theme-rtm {
  --color-text-primary: #000;
  --color-text-secondary: #9e9e9e;
  --color-text-accent-01: red;
  --color-text-action-primary: #000;
  --color-text-action-secondary: #000;
  --color-text-action-accent-01: red;
  --color-text-action-link: #000;
  --color-text-action-link-hover: #00aeef;
  --color-text-field-placeholder: #000;
  --color-text-field-active: #000;
  --color-brand-primary: red;
  --color-brand-secondary: red;
  --color-brand-accent-01: red;
  --color-background-primary: #fff;
  --color-background-secondary: #f7f7f7;
  --color-background-gold: #f6c100;
  --color-background-product-thumb: #f5f5f5;
  --color-background-accent-01: red;
  --color-background-category: #f4f6f9;
  --color-background-cart-banner: #effaff;
  --color-background-radio-disabled: #eee;
  --color-radio-checked-disabled: #9e9e9e;
  --color-state-success: #78b032;
  --color-background-checkbox-checked-disable: #eee;
  --color-state-success-light: #00aeef;
  --color-state-pending: #000;
  --color-state-pending-light: #8dc63f;
  --color-state-processing: red;
  --color-state-alert: #e20f14;
  --color-state-error: #e20f14;
  --color-state-critical: #e20f14;
  --color-state-critical-light: #ffc3b8;
  --color-state-warning: #e20f14;
  --color-state-inactive: #9e9e9e;
  --color-state-discount: red;
  --color-state-secondary: #78b032;
  --color-state-info: red;
  --color-state-alert-info: red;
  --color-alert-status-error: #e20f14;
  --color-interactive-action-primary: #00aeef;
  --color-interactive-action-primary-hover: red;
  --color-quantity-input-button-disabled: #9e9e9e;
  --color-interactive-action-secondary: #fff;
  --color-interactive-action-secondary-hover: #000;
  --color-interactive-action-accent-01: red;
  --color-interactive-action-accent-01-hover: red;
  --color-interactive-action-field: #e20f14;
  --color-interactive-action-focus: #00aeef;
  --color-border-line-primary: #bdbdbd;
  --color-border-line-seperate-primary: #eee;
  --color-border-line-seperate-secondary: red;
  --color-ecommerce-sale: #e02025;
  --color-ecommerce-reviews: #00aeef;
  --color-tags-sale: red;
  --color-slider-track: #212121;
  --color-slider-remaining-track: #e0e0e0;
  --color-checkbox-stroke-line: #212121;
  --checkbox-content-margin: 0 0 0 12px;
  --primary-blue-400: #2ccaff;

  // Input
  --input-label-color: var(--color-text-action-primary);
  --input-border-color: #eee;
  --input-border-color-focus: #000;
  --input-placeholder-color-focus: #757575;
  --input-text-color: var(--color-text-action-primary);
  --input-label-color-disabled: #9e9e9e;
  --input-bg-color-disabled: #eee;
  --input-border-color-disabled: var(--input-border-color);
  --input-text-color-disabled: #757575;
  --input-color-text_disabled: var(--input-text-color-disabled);
  --label-font-size: 14px;
  --label-line-height: 16px;
  --input-padding-default: 0 12px;
  --input-color-placeholder: #9e9e9e;

  // Button
  --btn-loading-spinner-color: var(--btn-solid-color-text-primary);
  --typography-button-font-weight: 800;
  --typography-button-font-size: 14px;
  --typography-button-line-height: 16px;
  --btn-focus-outline-offset: 2px;
  --btn-focus-outline-width: 2px;
  --btn-primary-disabled: #eee;
  --btn-primary-text-disabled: #9e9e9e;
  --btn-ghost-color-text-primary: #000;
  --btn-ghost-text-decoration: underline;
  --btn-ghost-color-text-disabled: var(--btn-primary-text-disabled);
  --btn-ghost-color-bg-disabled: transparent;
  --btn-ghost-color-border-disabled: transparent;
  --btn-solid-color-border-secondary: #000;
  --btn-solid-color-bg-secondary-hover: #f5f5f5;
  --btn-solid-color-text-secondary-hover: #000;
  --btn-solid-color-text-secondary: #000;
  --btn-solid-border-style: solid;
  --btn-size-default-border-width: 1px;
  --btn-solid-color-border-secondary-disabled: #bdbdbd;
  --btn-secondary-color-bg-disabled: #fff;
  --btn-secondary-color-text-disabled: var(--btn-primary-text-disabled);
  --btn-secondary-focus: var(--btn-solid-color-border-secondary);
  --btn-icon-disabled-opacity: 1;
  --btn-icon-color-bg-primary: #212121;
  --btn-icon-color-text-primary: #fff;
  --btn-icon-color-bg-primary-hover: #3a3a3a;
  --btn-icon-color-border-primary-hover: #3a3a3a;
  --btn-icon-color-outline-primary-focus: #212121;
  --btn-icon-color-bg-disabled: #616161;
  --btn-size-md-padding: 16px;
  --btn-size-md-height: 48px;
  --btn-font-weight: 700;
  --btn-link-color: #000;
  --btn-success-color: #00aeef;
  --btn-success-color-hover: var(--primary-blue-400);
  --color-progress-bar: var(--color-state-success-light);

  // Range Slider
  --color-slider-thumb: #212121;

  // Typography
  --color-typography-heading: #000;
  --color-typography-body: #000;

  // Heading 1
  --typography-heading-1-font-size: 56px;
  --typography-heading-1-font-weight: 400;
  --typography-heading-1-line-height: 62px;
  --typography-heading-1-letter-spacing: -0.9px;

  // Heading 2
  --typography-heading-2-font-size: 44px;
  --typography-heading-2-font-weight: 400;
  --typography-heading-2-line-height: 56px;
  --typography-heading-2-letter-spacing: -0.85px;

  // Heading 3
  --typography-heading-3-font-size: 36px;
  --typography-heading-3-font-weight: 400;
  --typography-heading-3-line-height: 48px;
  --typography-heading-3-letter-spacing: -0.85px;

  // Heading 4
  --typography-heading-4-font-size: 32px;
  --typography-heading-4-font-weight: 400;
  --typography-heading-4-line-height: 36px;
  --typography-heading-4-letter-spacing: -0.85px;

  // Heading 5
  --typography-heading-5-font-size: 22px;
  --typography-heading-5-font-weight: 400;
  --typography-heading-5-line-height: 26px;
  --typography-heading-5-letter-spacing: -0.7px;

  // Heading 6
  --typography-heading-6-font-size: 18px;
  --typography-heading-6-font-weight: 400;
  --typography-heading-6-line-height: 24px;
  --typography-heading-6-letter-spacing: -0.6px;

  // bodyXLarge
  --typography-body-xlarge-bold-font-weight: 700;

  // bodyLarge
  --typography-body-large-bold-font-weight: 700;

  //bodyregular
  --typography-body-regular-bold-font-weight: 700;
  --typography-body-regular-letter-spacing: 0.15px;

  //bodySmall
  --typography-body-small-bold-font-weight: 700;
  --typography-body-small-letter-spacing: 0.15px;

  //Select
  --select-option-bg-color-hover: #fafafa;
  --select-option-bg-color-selected: #f5f5f5;
  --select-option-bg-color-disabled: #fff;
  --select-option-text-color-default: #3a3a3a;
  --select-option-text-color-hover-selected: #000;
  --select-option-text-color-disabled: #9e9e9e;

  // Header
  --header-color-bg: #212121;
  --header-color-border: #eee;
  --header-color-separator: #e0e0e0;
  --header-mobile-color-separator: #3a3a3a;

  // BenefitCards
  --benefit-cards-color-title: #12306d;
  --benefit-cards-color-description: #3a3a3a;

  // Search Results
  --search-results-color-muted: #757575;
  --search-results-color-border: #eee;

  // Product Card
  --product-card-color-bg-tag: #ff3237;

  // New Colors
  --color-primary-muted: #757575;
  --color-secondary-muted: #3a3a3a;
  --color-border-primary: #eee;
  --color-background-primary-muted: #212121;
  --color-state-success-alt: var(--color-state-success);

  // Navigation Card
  --navigation-card-color-icon: #00aeef;
  --navigation-card-color-description: #3a3a3a;

  // Footer
  --footer-bg-color: #212121;
  --footer-call-centre-list-gap: 12px;
  --footer-social-media-platform-padding-bottom: 32px;
  --footer-social-media-platform-padding-top: 24px;
  --footer-social-media-platform-padding-top-lg: 16px;
  --footer-links-gap: 24px;
  --footer-links-list-gap: 12px;
  --footer-links-padding-top: 56px;

  // Authentication Drawer
  --authentication-drawer-color-step-inactive: #757575;
  --authentication-drawer-color-step-success: #8dc63f;
  --authentication-drawer-color-validation-success: var(--color-state-success);
  --authentication-drawer-color-validation-base: #bdbdbd;

  // TextAndImage
  --text-and-image-color-description: #3a3a3a;

  // Store
  --color-store-btn: #212121;
  --color-store-status: #8dc63f;

  // Product Main
  --quantity-selector-color-button: #000;
  --product-status-tooltip-color: #00aeef;
  --card-color-bg-secondary: rgb(231, 245, 210);
  --card-color-border-secondary: transparent;

  // Accordion
  --accordion-title-horizontal-padding: 16px 0;
  --accordion-title-vertical-padding: 16px 16px;
  --accordion-icon-size: 24px;
  --accordion-title-color-disabled: #000;

  // Product Comparison
  --product-comparison-color-bg-table: #f5f5f5;
  --product-comparison-color-btn: #fff;
  --product-comparison-color-bg-btn: #000;
  --status-color-bg-success: #8dc63f;
  --switch-color-bg-off: #e0e0e0;
  --switch-color-bg-on: #8dc63f;

  // Product Details
  --product-details-color-bg-cell: #f5f5f5;

  // Breadcrumbs
  --breadcrumbs-color: #757575;
  --breadcrumbs-color-icon: #757575;

  // CardsCarousel
  --cards-carousel-color-title: #212121;

  // PromoBanner
  --promobanner-btn-bg-color: #00aeef;

  // OrderDeliveryCard
  --order-delivery-card-color-summary: #757575;
  --order-delivery-card-color-step-inactive: var(--color-state-inactive);

  // ListDetails
  --list-details-color-bg-summary: #fafafa;

  // CustomerLists
  --customer-lists-color-bg-warning: #effaff;
  --customer-lists-color-border-warning: #00aeef;

  // ListCard
  --list-card-color--text-date-time: var(--color-primary-muted);
  --list-card-font-weight-date-time: var(--typography-body-small-font-weight);
  --list-card-color-text-product-count: var(--color-secondary-muted);
  --list-card-font-weight-product-count: var(
    --typography-body-regular-font-weight
  );

  // OrderConfirmation
  --order-confirmation-color-bg-top: #f4f6f9;

  // FourZeroFour
  --fourzerofour-content-margin-top: 0;
  --fourzerofour-sm-content-margin-top: 0;
  --fourzerofour-border-color: #fff;
  --fourzerofour-para-color: #eee;

  // Cart Drawer
  --cart-upsell-slider-color-bg: #fff;

  // CartItem
  --cart-item-color-warning-text: #e76d0f;

  // CustomerSavedAddress
  --customer-saved-address-color-text: var(--color-primary-muted);
  --customer-saved-address-color-bg-tag-other: #f5f5f5;
  --customer-saved-address-color-text-tag-other: #757575;
  --customer-saved-address-color-bg-tag-business-home: #b6eaff;
  --customer-saved-address-color-text-tag-business-home: #00aeef;

  // CreateCustomerSavedAddressCard
  --create-customer-saved-address-color-icon: #00aeef;

  // CategoryCard
  --category-card-link-border-color: #000;

  // MegaMenu
  --mega-menu-color-link-primary: #eee;
  --mega-menu-color-link-secondary: #3a3a3a;
  --mega-menu-color-link-muted: #bdbdbd;
  --mega-menu-color-border-primary: #3a3a3a;

  // AccountOrderReturn
  --account-order-return-select-btn-color: #3a3a3a;

  //CategoryTile
  --category-tile-title-color: #212121;

  //CompareProductsSnackbar
  --compare-product-fillBtn-color: #00aeef;
  --compare-product-outlineBtn-color: #fff;

  //FilterItem
  --filter-item-suffix-color: #3a3a3a;

  //OrderDeliveryDetailsCard
  --progress-text-color-disabled: #9e9e9e;

  //ProductListFilters
  --product-list-filters-color-separator: #e0e0e0;

  // VideoCarousel
  --video-carousel-color-bg-btn: #212121;
  --video-carousel-color-bg-btn-hover: #000;
  --video-carousel-color-btn: var(--color-grayscale-white);
}
