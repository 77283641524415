@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'reset';
@import 'base';
@import 'main';
@import 'adyen';
@import 'transitions';
@import 'components';
@import '@overdose/components/build/esm/styles.css';
@import './theme/theme';
@import './makerhub/code';
@import 'nprogress.css';

#main-content {
  display: block;
  position: relative;
  min-height: calc(100vh - 80px);
  @media screen and (--desktop) {
    min-height: calc(100vh - 140px);
  }
}

:where(#main-content)
  > :is(div, section, article, aside, header, footer):not(:first-child) {
  margin-top: var(--section-spacing-default);
}

:where(#main-content)
  > :is(div, section, article, aside, header, footer):not(:last-child) {
  margin-bottom: var(--section-spacing-default);
}

.gm-style {
  .gm-style-iw-c {
    padding: 0;

    .gm-style-iw-d {
      overflow: auto !important;

      & + button {
        display: none !important;
      }
    }
  }

  .gm-style-iw-tc {
    height: 6px;
    width: 12px;

    &::after {
      height: 6px;
      width: 12px;
    }
  }
}

.pac-container {
  z-index: var(--drawer-z-index);
}

.gm-breadcrumbs:has(+ .textSection) .mobile-section {
  padding-bottom: 0;
}

.textSection + .productListingSection {
  margin-top: 0;
}

.productListingSection + .textSection .textSection__content {
  max-width: 872px!important;
  margin: 0 auto;
  padding-top: 22px;
  padding-bottom: 22px;

  --typography-heading-2-font-size: 28px;
  --typography-heading-2-line-height: 32px;
  --typography-heading-3-font-size: 24px;
  --typography-heading-3-line-height: 26px;
  --typography-heading-4-font-size: 20px;
  --typography-heading-4-line-height: 24px;
  --typography-heading-5-font-size: 16px;
  --typography-heading-5-line-height: 18px;
  --typography-heading-6-font-size: 14px;
  --typography-heading-6-line-height: 16px;
}

.gm-style-iw-chr {
  display: none;
}

.gm-breadcrumbs {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  --section-spacing-default: 0;
}

.gm-breadcrumbs:has(+ .absolute-top-6) {
  position: absolute;
  top: 24px;
  width: 100%;

  .mobile-section,
  .desktop-section {
    padding-top: 0;
  }
}

@media screen and (--mobile) {
  .gm-breadcrumbs:has(+ .absolute-top-6) {
    top: 16px;
  }
}

.scroll-padTop {
  scroll-padding-top: 13rem;

  @media screen and (--desktop) {
    scroll-padding-top: 20rem;
  }
}

.section-loading-placeholder,
.section-loading-placeholder ~ * {
  display: none;
}

body {
  color: var(--color-text-primary);
}

.Base-Drawer {
  height: 83%!important;
  @media screen and (--mobile) {
    height: 77%!important;
  }
}

.Base-Breadcrumbs {
  width: 100%;
  overflow: hidden;
}

/* stylelint-disable */
#bv_review_maincontainer svg {
  display: inline;
  vertical-align: unset;
}

#bv_review_maincontainer * {
  font-family: var(--font-family-primary),
    var(--typography-body-fallback-font-stack) !important;
}

#bv_review_maincontainer > div:first-child {
  display: none;
}

#bv_review_maincontainer > div:nth-child(2) > div:last-child {
  border-bottom: none !important;
}

#Reviews-panel.Base-Accordion--Expanded {
  height: auto !important;
  overflow: auto !important;
}

.rating_summary[data-bv-show='rating_summary'] .bv_main_container {
  line-height: 16px !important;

  .bv_button_buttonMinimalist,
  .bv_avgRating_component_container,
  .bv_numReviews_component_container {
    font-family: var(--font-family-primary) !important;

    &:hover {
      font-family: var(--font-family-primary) !important;
    }
  }

  .bv_stars_component_container,
  .bv_main_container_row_flex {
    padding-right: 0.5rem !important;
  }

  // if there are reviews, but ratingCount from CT is 0, show rating_summary container
  // if there aren't any reviews and ratingCount from CT is 0, hide rating_summary container
  &:not(:has(button.bv_main_container_row_flex)) {
    display: none !important;
  }
}

.text-wrap {
  text-wrap: wrap;
}

.search-result-container {
  --offset-top-size: 200px;
  position: fixed;
  max-height: calc(var(--vh, 1vh) * 100 - var(--offset-top-size));
  overflow-y: auto;
  
  @media screen and (--desktop) {
    max-height: calc(100vh - 110px);
    overflow-y: hidden;
  }
}

.search-result-section {
  @media screen and (--desktop) {
    overflow-y: auto;
    max-height: calc(100vh - 110px);
  }
}

@media screen and (--mobile) {
  input,
  select {
    font-size: 16px;

    --input-text-font-size: 16px;
  }
}

.footer-padding {
  @media screen and (max-width: 1024px) {
    padding-bottom: 6rem;
  }
}

.Base-Select--Disabled {
  border-color: var(--input-border-color-disabled);
  background-color: var(--input-bg-color-disabled);
}

.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    background-color: #0c254c;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    color: white;

    &::after {
      font-size: 1rem;
    }
  }
}

.cslp-tooltip {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden !important;
}