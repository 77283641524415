// Root
.root.root {
  --btn-icon-size: 24px;

  &::before,
  &::after {
    background-color: transparent;
  }

  &:focus-visible {
    outline: var(--btn-focus-outline-width) solid
      var(--color-interactive-action-focus);
    outline-offset: var(--btn-focus-outline-offset);
  }

  &:disabled:not(.ghost, .secondary, .link),
  &:disabled:hover:not(.ghost, .secondary, .link),
  &.loading:not(.ghost, .secondary, .link) {
    background-color: var(--btn-primary-disabled);
    color: var(--btn-primary-text-disabled);
    border-color: var(--btn-primary-disabled);
    cursor: not-allowed;
    border: 0;
  }

  &.loading:not(.iconOnly) {
    --btn-icon-size: 1rem;

    min-width: max-content;
  }
}

// Secondary (Outline) Variant
.secondary.secondary {
  &:focus-visible {
    outline-color: var(--btn-secondary-focus);
  }

  &:disabled,
  &:disabled:hover:hover,
  &.loading {
    border-width: 0;
    background-color: var(--btn-secondary-color-bg-disabled);
    color: var(--btn-secondary-color-text-disabled);
    border-color: var(--btn-solid-color-border-secondary-disabled);
  }

  &.loading {
    --btn-icon-size: 1rem;
  }
}

// Ghost Variant
.ghost.ghost {
  border: 0 none;
  text-decoration: var(--btn-ghost-text-decoration);

  &:hover:not(:disabled, [data-hover='nohover']) {
    background-color: var(--btn-solid-color-bg-secondary-hover);
  }

  &:focus-visible {
    outline-color: var(--btn-secondary-focus);
  }

  &:disabled,
  &:disabled:hover,
  &.loading {
    color: var(--btn-ghost-color-text-disabled);
    background-color: var(--btn-ghost-color-bg-disabled);
    border-color: var(--btn-ghost-color-border-disabled);
  }

  &.loading {
    --btn-icon-size: 1rem;
  }
}

//  Icon variant
.iconOnly.iconOnly {
  --btn-solid-color-bg-primary: var(--btn-icon-color-bg-primary);
  --btn-solid-color-text-primary: var(--btn-icon-color-text-primary);
  --btn-solid-color-bg-primary-hover: var(--btn-icon-color-bg-primary-hover);
  --btn-solid-color-text-primary-hover: var(--btn-icon-color-text-primary);
  --btn-solid-color-border-primary-hover: var(
    --btn-icon-color-border-primary-hover
  );
  --color-interactive-action-focus: var(--btn-icon-color-outline-primary-focus);

  &:disabled {
    --btn-primary-disabled: var(--btn-icon-color-bg-disabled);
    --btn-ghost-color-bg-primary: var(--btn-ghost-color-bg-disabled);
    --btn-ghost-color-text-primary: var(--btn-ghost-color-text-disabled);
  }

  &.lg,
  &.lg.loading {
    min-width: var(--btn-size-lg-height);
  }

  &.md,
  &.md.loading {
    min-width: var(--btn-size-md-height);
  }

  &.sm,
  &.sm.loading {
    --btn-size-sm-height: 40px;

    min-width: var(--btn-size-sm-height);
  }

  &.xs,
  &.xs.loading {
    --btn-icon-size: 16px;
    --btn-size-xs-height: 24px;

    min-width: var(--btn-size-xs-height);
  }
}

.lg {
  --btn-font-size: 18px;
  --btn-line-height: 24px;
}

.xs {
  --btn-font-size: 12px;
  --btn-line-height: 16px;
}

.loading {
  cursor: not-allowed;
}

.link.disabled {
  background-color: transparent;
  opacity: 0.5;
}
