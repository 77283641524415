:root {
  /* font */
  --radio-label-font-size: var(--typography-body-font-size);
  --radio-label-line-height: var(--typography-body-line-height);
  --radio-label-font-family: var(--typography-body-font-family-stack);
  --radio-label-letter-spacing: var(--typography-body-letter-spacing);

  /* border color */
  --radio-border-color: var(--color-border-line-primary);
  --radio-bg-color-active: var(--color-interactive-action-primary);
  --radio-border-color-hover: var(--color-interactive-action-focus);
  --radio-bg-color-disabled: var(--color-interactive-action-secondary);

  /* boxed */
  --radio-border-radius-boxed: 5px;

  /* small */
  --radio-size-sm: 16px;
  --radio-padding-sm: 0;

  /* medium */
  --radio-size-md: 18px;
  --radio-padding-md: 2px;

  /* large */
  --radio-size-lg: 24px;
  --radio-padding-lg: 4px;

  /* label */
  --radio-label-size: var(--radio-font-size);
  --radio-label-color: var(--color-text-primary);
  --radio-label-color-disabled: var(--color-text-secondary);

  /* hint */
  --radio-hint-font-size: var(--typography-body-caption-font-size);
  --radio-hint-color: var(--color-text-secondary);

  /* radio as button */
  --radio-button-padding-horizontal: 0;
  --radio-button-color-bg: var(--color-interactive-action-secondary);
  --radio-button-color-bg-hover: var(--color-white);

  /** Vertical Paddings **/
  --radio-vertical-padding: 7px 0;
}
