:root {
  --btn-border-radius-default: 4px;
  --btn-shadow-default: none;
  --btn-border-width: 1px;

  /** border styles **/
  --btn-outline-border-style: --border-solid;
  --btn-primary-border-style: --border-solid;
  --btn-secondary-border-style: --border-solid;

  /** Button Icon **/
  --btn-icon-spacing: 8px;
  --btn-icon-vertical-align: -2px;
  --btn-icon-size: 16px;
  --btn-icon-disabled-opacity: 0.5;

  /** Loading **/
  --btn-loading-spinner-transform-start: rotate(0);
  --btn-loading-spinner-transform-end: rotate(360deg);
  --btn-loading-spinner-color: var(--btn-solid-color-text-primary);
  --btn-loading-spinner-animation: anim-rotate 2s infinite linear;

  /** Flat / Raised **/
  --btn-raised-shadow: var(--shadow-100);
  --btn-flat-shadow: none;

  /** Square Button **/
  --btn-square-border-radius: 4px;

  /** Circle Button **/
  --btn-circle-border-radius: 50%;

  /** Rounded Button **/
  --btn-rounded-border-radius: 25px;

  /** Button Typography **/
  --btn-font-size: var(--typography-button-font-size);
  --btn-font-weight: var(--typography-button-font-weight);
  --btn-line-height: var(--typography-button-line-height);
  --btn-color: var(--color-text-action-link);
  --btn-letter-spacing: var(--typography-button-letter-spacing);
  --btn-font-family: var(--typography-button-font-family-stack);
  --btn-font-style: var(--typography-button-font-style);
  --btn-text-transform: var(--typography-button-text-transform);

  /** Button Size **/
  --btn-size-xs-padding: 0 10px;
  --btn-size-xs-height: 32px;
  --btn-size-padding: 0 12px;
  --btn-size-sm-height: 34px;
  --btn-size-sm-padding: 0 12px;
  --btn-size-md-height: 48px;
  --btn-size-md-padding: 0 16px;
  --btn-size-lg-height: 56px;
  --btn-size-lg-padding: 0 16px;

  /** Solid Button **/
  --btn-solid-border-width: 1px;
  --btn-solid-border-type: solid;

  /** Outline Button **/
  --btn-outline-border-width: 1px;
  --btn-outline-border-type: var(--btn-solid-border-type);

  /** Dashed Button **/
  --btn-dashed-border-width: 1px;
  --btn-dashed-border-type: dashed;

  /** Ghost Button **/
  --btn-ghost-border-width: 1px;
  --btn-ghost-border-type: var(--btn-solid-border-type);

  /** Link Button **/
  --btn-link-color: var(--btn-color);
  --btn-link-color-hover: var(--color-text-action-link-hover);
  --btn-link-padding: 0;
  --btn-link-height: min-content;
  --btn-link-text-decoration: none;
  --btn-link-text-decoration-hover: underline;
  --section-title-link-color: var(--color-text-action-link);

  /***** Warning Colors *****/
  --btn-warning-color: var(--color-state-warning);
  --btn-warning-text-color: var(--color-text-action-primary);
  --btn-warning-color-hover: #ffd483ad;
  --btn-warning-text-color-hover: var(--color-text-action-primary);

  /***** Error Colors *****/
  --btn-error-color: var(--color-state-critical);
  --btn-error-text-color: var(--color-text-action-primary);
  --btn-error-color-hover: var(--color-state-critical-light);
  --btn-error-text-color-hover: var(--color-text-action-primary);

  /***** Info Colors *****/
  --btn-info-color: #519dfc;
  --btn-info-text-color: var(--color-text-action-primary);
  --btn-info-color-hover: #3e8ff3;
  --btn-info-text-color-hover: var(--color-text-action-primary);

  /***** Success Colors *****/
  --btn-success-color: var(--color-state-success);
  --btn-success-text-color: var(--color-text-action-primary);
  --btn-success-color-hover: var(--color-state-success-light);
  --btn-success-text-color-hover: var(--color-text-action-primary);

  /***** Primary Solid *****/
  --btn-solid-color-bg-primary: var(--color-interactive-action-primary);
  --btn-solid-color-text-primary: var(--color-text-action-primary);
  --btn-solid-color-border-primary: transparent;
  --btn-solid-color-border-primary-hover: var(--primary-blue-400);
  --btn-solid-color-text-primary-hover: var(--color-text-action-primary);
  --btn-solid-color-bg-primary-hover: var(--primary-blue-400);

  /***** Primary Outline *****/
  --btn-outline-color-bg-primary: transparent;
  --btn-outline-color-text-primary: var(--color-interactive-action-primary);
  --btn-outline-color-border-primary: var(--color-interactive-action-primary);
  --btn-outline-color-border-primary-hover: var(
    --color-interactive-action-primary
  );
  --btn-outline-color-text-primary-hover: var(--color-text-action-primary);
  --btn-outline-color-bg-primary-hover: var(--color-interactive-action-primary);

  /***** Primary Dashed *****/
  --btn-dashed-color-bg-primary: transparent;
  --btn-dashed-color-text-primary: var(--color-interactive-action-primary);
  --btn-dashed-color-border-primary: var(--color-interactive-action-primary);
  --btn-dashed-color-border-primary-hover: var(
    --color-interactive-action-primary
  );
  --btn-dashed-color-text-primary-hover: var(--color-text-action-primary);
  --btn-dashed-color-bg-primary-hover: var(--color-interactive-action-primary);

  /***** Primary Ghost *****/
  --btn-ghost-color-bg-primary: transparent;
  --btn-ghost-color-text-primary: var(--color-interactive-action-primary);
  --btn-ghost-color-border-primary: transparent;
  --btn-ghost-color-border-primary-hover: var(
    --color-interactive-action-primary
  );
  --btn-ghost-color-text-primary-hover: var(--color-interactive-action-primary);
  --btn-ghost-color-bg-primary-hover: var(--greyscale-100);

  /***** Secondary Solid *****/
  --btn-solid-color-bg-secondary: var(--color-interactive-action-secondary);
  --btn-solid-color-text-secondary: var(--color-text-action-secondary);
  --btn-solid-color-border-secondary: var(--color-interactive-action-primary);
  --btn-solid-color-border-secondary-hover: var(
    --color-interactive-action-secondary-hover
  );
  --btn-solid-color-text-secondary-hover: var(--color-text-action-secondary);
  --btn-solid-color-bg-secondary-hover: var(
    --color-interactive-action-secondary
  );

  /***** Secondary Outline *****/
  --btn-outline-color-bg-secondary: transparent;
  --btn-outline-color-text-secondary: var(--color-text-action-secondary);
  --btn-outline-color-border-secondary: var(
    --color-interactive-action-secondary
  );
  --btn-outline-color-border-secondary-hover: var(
    --color-interactive-action-secondary
  );
  --btn-outline-color-text-secondary-hover: var(--color-text-action-secondary);
  --btn-outline-color-bg-secondary-hover: var(
    --color-interactive-action-secondary
  );

  /***** Secondary Dashed *****/
  --btn-dashed-color-bg-secondary: transparent;
  --btn-dashed-color-text-secondary: var(--color-text-action-secondary);
  --btn-dashed-color-border-secondary: var(
    --color-interactive-action-secondary
  );
  --btn-dashed-color-border-secondary-hover: var(
    --color-interactive-action-secondary
  );
  --btn-dashed-color-text-secondary-hover: var(--color-text-action-secondary);
  --btn-dashed-color-bg-secondary-hover: var(
    --color-interactive-action-secondary
  );

  /***** Secondary Ghost *****/
  --btn-ghost-color-bg-secondary: transparent;
  --btn-ghost-color-text-secondary: var(--color-text-action-secondary);
  --btn-ghost-color-border-secondary: transparent;
  --btn-ghost-color-border-secondary-hover: var(
    --color-interactive-action-secondary
  );
  --btn-ghost-color-text-secondary-hover: var(--color-text-action-secondary);
  --btn-ghost-color-bg-secondary-hover: var(
    --color-interactive-action-secondary
  );

  /***** Accent Solid *****/
  --btn-solid-color-bg-accent: var(--color-grayscale-white);
  --btn-solid-color-text-accent: var(--color-text-action-secondary);
  --btn-solid-color-border-accent: transparent;
  --btn-solid-color-border-accent-hover: transparent;
  --btn-solid-color-text-accent-hover: var(--color-text-action-secondary);
  --btn-solid-color-bg-accent-hover: var(--color-background-secondary);

  /***** Accent Outline *****/
  --btn-outline-color-bg-accent: transparent;
  --btn-outline-color-text-accent: var(--color-grayscale-white);
  --btn-outline-color-border-accent: var(--color-grayscale-white);
  --btn-outline-color-border-accent-hover: var(--color-grayscale-white);
  --btn-outline-color-text-accent-hover: var(--color-text-action-secondary);
  --btn-outline-color-bg-accent-hover: var(--color-grayscale-white);

  /***** Accent Dashed *****/
  --btn-dashed-color-bg-accent: transparent;
  --btn-dashed-color-text-accent: var(--color-grayscale-white);
  --btn-dashed-color-border-accent: var(--color-grayscale-white);
  --btn-dashed-color-border-accent-hover: var(--color-grayscale-white);
  --btn-dashed-color-text-accent-hover: var(--color-text-action-secondary);
  --btn-dashed-color-bg-accent-hover: var(--color-grayscale-white);

  /***** Accent Ghost *****/
  --btn-ghost-color-bg-accent: transparent;
  --btn-ghost-color-text-accent: var(--color-grayscale-white);
  --btn-ghost-color-border-accent: transparent;
  --btn-ghost-color-border-accent-hover: var(
    --color-interactive-action-secondary
  );
  --btn-ghost-color-text-accent-hover: var(--color-text-action-secondary);
  --btn-ghost-color-bg-accent-hover: var(--color-interactive-action-secondary);

  /***** Warning Solid *****/
  --btn-solid-color-bg-warning: var(--btn-warning-color);
  --btn-solid-color-text-warning: var(--btn-warning-text-color);
  --btn-solid-color-border-warning: transparent;
  --btn-solid-color-border-warning-hover: transparent;
  --btn-solid-color-text-warning-hover: var(--btn-warning-text-color-hover);
  --btn-solid-color-bg-warning-hover: var(--btn-warning-color-hover);

  /***** Warning Outline *****/
  --btn-outline-color-bg-warning: transparent;
  --btn-outline-color-text-warning: var(--btn-warning-color);
  --btn-outline-color-border-warning: var(--btn-warning-color);
  --btn-outline-color-border-warning-hover: var(--btn-warning-color);
  --btn-outline-color-text-warning-hover: var(--btn-warning-text-color);
  --btn-outline-color-bg-warning-hover: var(--btn-warning-color);

  /***** Warning Dashed *****/
  --btn-dashed-color-bg-warning: transparent;
  --btn-dashed-color-text-warning: var(--btn-warning-color);
  --btn-dashed-color-border-warning: var(--btn-warning-color);
  --btn-dashed-color-border-warning-hover: var(--btn-warning-color);
  --btn-dashed-color-text-warning-hover: var(--btn-warning-text-color);
  --btn-dashed-color-bg-warning-hover: var(--btn-warning-color);

  /***** Warning Ghost *****/
  --btn-ghost-color-bg-warning: transparent;
  --btn-ghost-color-text-warning: var(--btn-warning-color);
  --btn-ghost-color-border-warning: transparent;
  --btn-ghost-color-border-warning-hover: var(--btn-warning-color);
  --btn-ghost-color-text-warning-hover: var(--btn-warning-text-color);
  --btn-ghost-color-bg-warning-hover: var(--btn-warning-color);

  /***** Error Solid *****/
  --btn-solid-color-bg-error: var(--btn-error-color);
  --btn-solid-color-text-error: var(--btn-error-text-color);
  --btn-solid-color-border-error: transparent;
  --btn-solid-color-border-error-hover: transparent;
  --btn-solid-color-text-error-hover: var(--btn-error-text-color-hover);
  --btn-solid-color-bg-error-hover: var(--btn-error-color-hover);

  /***** Error Outline *****/
  --btn-outline-color-bg-error: transparent;
  --btn-outline-color-text-error: var(--btn-error-color);
  --btn-outline-color-border-error: var(--btn-error-color);
  --btn-outline-color-border-error-hover: var(--btn-error-color);
  --btn-outline-color-text-error-hover: var(--btn-error-text-color);
  --btn-outline-color-bg-error-hover: var(--btn-error-color);

  /***** Error Dashed *****/
  --btn-dashed-color-bg-error: transparent;
  --btn-dashed-color-text-error: var(--btn-error-color);
  --btn-dashed-color-border-error: var(--btn-error-color);
  --btn-dashed-color-border-error-hover: var(--btn-error-color);
  --btn-dashed-color-text-error-hover: var(--btn-error-text-color);
  --btn-dashed-color-bg-error-hover: var(--btn-error-color);

  /***** Error Ghost *****/
  --btn-ghost-color-bg-error: transparent;
  --btn-ghost-color-text-error: var(--btn-error-color);
  --btn-ghost-color-border-error: transparent;
  --btn-ghost-color-border-error-hover: var(--btn-error-color);
  --btn-ghost-color-text-error-hover: var(--btn-error-text-color);
  --btn-ghost-color-bg-error-hover: var(--btn-error-color);

  /***** Info Solid *****/
  --btn-solid-color-bg-info: var(--btn-info-color);
  --btn-solid-color-text-info: var(--btn-info-text-color);
  --btn-solid-color-border-info: transparent;
  --btn-solid-color-border-info-hover: transparent;
  --btn-solid-color-text-info-hover: var(--btn-info-text-color-hover);
  --btn-solid-color-bg-info-hover: var(--btn-info-color-hover);

  /***** Info Outline *****/
  --btn-outline-color-bg-info: transparent;
  --btn-outline-color-text-info: var(--btn-info-color);
  --btn-outline-color-border-info: var(--btn-info-color);
  --btn-outline-color-border-info-hover: var(--btn-info-color);
  --btn-outline-color-text-info-hover: var(--btn-info-text-color);
  --btn-outline-color-bg-info-hover: var(--btn-info-color);

  /***** Info Dashed *****/
  --btn-dashed-color-bg-info: transparent;
  --btn-dashed-color-text-info: var(--btn-info-color);
  --btn-dashed-color-border-info: var(--btn-info-color);
  --btn-dashed-color-border-info-hover: var(--btn-info-color);
  --btn-dashed-color-text-info-hover: var(--btn-info-text-color);
  --btn-dashed-color-bg-info-hover: var(--btn-info-color);

  /***** Info Ghost *****/
  --btn-ghost-color-bg-info: transparent;
  --btn-ghost-color-text-info: var(--btn-info-color);
  --btn-ghost-color-border-info: transparent;
  --btn-ghost-color-border-info-hover: var(--btn-info-color);
  --btn-ghost-color-text-info-hover: var(--btn-info-text-color);
  --btn-ghost-color-bg-info-hover: var(--btn-info-color);

  /***** Success Solid *****/
  --btn-solid-color-bg-success: var(--btn-success-color);
  --btn-solid-color-text-success: var(--btn-success-text-color);
  --btn-solid-color-border-success: transparent;
  --btn-solid-color-border-success-hover: transparent;
  --btn-solid-color-text-success-hover: var(--btn-success-text-color-hover);
  --btn-solid-color-bg-success-hover: var(--btn-success-color-hover);

  /***** Success Outline *****/
  --btn-outline-color-bg-success: transparent;
  --btn-outline-color-text-success: var(--btn-success-color);
  --btn-outline-color-border-success: var(--btn-success-color);
  --btn-outline-color-border-success-hover: var(--btn-success-color);
  --btn-outline-color-text-success-hover: var(--btn-success-text-color);
  --btn-outline-color-bg-success-hover: var(--btn-success-color);

  /***** Success Dashed *****/
  --btn-dashed-color-bg-success: transparent;
  --btn-dashed-color-text-success: var(--btn-success-color);
  --btn-dashed-color-border-success: var(--btn-success-color);
  --btn-dashed-color-border-success-hover: var(--btn-success-color);
  --btn-dashed-color-text-success-hover: var(--btn-success-text-color);
  --btn-dashed-color-bg-success-hover: var(--btn-success-color);

  /***** Success Ghost *****/
  --btn-ghost-color-bg-success: transparent;
  --btn-ghost-color-text-success: var(--btn-success-color);
  --btn-ghost-color-border-success: transparent;
  --btn-ghost-color-border-success-hover: var(--btn-success-color);
  --btn-ghost-color-text-success-hover: var(--btn-success-text-color);
  --btn-ghost-color-bg-success-hover: var(--btn-success-color);
}
