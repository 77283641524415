:root {
  /* Placeholder */
  --select-placeholder-color: var(--greyscale-600);
  --select-placeholder-font-family: var(--typography-body-font-family-stack);
  --select-placeholder-font-size: var(--typography-body-font-size);
  --select-placeholder-line-height: var(--typography-body-line-height);
  --select-placeholder-text-decoration: var(--typography-body-text-decoration);
  --select-placeholder-font-weight: var(--typography-body-font-weight);
  --select-placeholder-letter-spacing: var(--typography-body-letter-spacing);
  --select-placeholder-font-size-active: var(--typography-caption-font-size);

  /* Label */
  --select-label-color: var(--color-text-field-placeholder);
  --select-label-font-family: var(--typography-caption-font-family-stack);
  --select-label-font-size: var(--typography-body-font-size);
  --select-label-line-height: var(--typography-caption-line-height);
  --select-label-text-decoration: var(--typography-caption-text-decoration);
  --select-label-font-weight: var(--typography-caption-font-weight);
  --select-label-letter-spacing: var(--typography-caption-letter-spacing);

  /** Value */
  --select-value-color: var(--color-text-field-active);

  /**********************************************
 * type: single / multi
 * size: mini / small / default / large
 **********************************************/
  --select-xs-height: 36px;
  --select-sm-height: 40px;
  --select-md-height: 46px;
  --select-lg-height: 50px;

  /* Font Sizes */
  --select-md-font-size: 14px;
  --select-sm-font-size: 14px;
  --select-lg-font-size: 14px;
  --select-xs-font-size: 12px;

  /* Paddings */
  --select-xs-padding: 8px;
  --select-sm-padding: 12px;
  --select-md-padding: 12px;
  --select-lg-padding: 12px;
  --select-multi-padding: 4px;
  --select-icon: 12px;
  --select-svg: 16px;

  /* select Color */
  --select-bg-color: var(--color-grayscale-white);
  --select-bg-color-hover: var(--color-grayscale-white);
  --select-bg-color-focus: var(--color-grayscale-white);
  --select-bg-color-disabled: var(--color-state-inactive);
  --select-text-color: var(--color-text-primary);

  /* select filled */
  --select-filled-bg-color: var(--color-background-secondary);
  --select-filled-bg-color-focus: var(--color-background-secondary);

  /* textarea  color */
  --textarea-tip-text-color: var(--color-text-field-placeholder);

  /* Error State */
  --select-bg-color-error: var(--color-grayscale-white);
  --select-bg-color-hover-error: var(--color-grayscale-white);
  --select-bg-color-focus-error: var(--color-grayscale-white);

  /* Borders */
  --select-border-width: 1px;
  --select-border-radius: 3px;
  --select-border-color: var(--color-border-line-primary);
  --select-border-color-disabled: var(--color-interactive-action-field);
  --select-border-color-hover: var(--input-border-color-focus);
  --select-border-color-focus: var(--color-interactive-action-focus);
  --select-border-color-error: var(--color-state-critical);
  --select-border-color-error-hover: var(--color-state-critical);
  --select-border-color-warning: var(--color-state-warning);
  --select-border-color-success: var(--color-state-success);

  /* suffix，addon */
  --select-prefix-text-color: var(--color-text-primary);
  --select-suffix-text-color: var(--color-text-primary);
  --select-tip-text-color: var(--color-text-field-placeholder);
  --select-icon-clear-color: var(--color-text-field-placeholder);
  --select-icon-clear-bg-color-hover: var(--color-text-primary);
  --select-addon-border: 1px solid var(--color-border-line-primary);

  /* select popup */
  --select-popup-max-height: 200px;
  --select-popup-border-radius: 6px;
  --select-popup-padding-vertical: 4px;
  --select-popup-font-size: 14px;
  --select-popup-letter-spacing: 0.005em;
  --select-popup-color-bg: var(--color-grayscale-white);
  --select-popup-color-border: var(--color-border-line-primary);
  --select-popup-box-shadow: 0 16px 20px -8px rgba(17, 12, 34, 10%);

  /**********************************************
 * Popup Options
 * status: default / disabled / selected / hover
 **********************************************/
  --select-popup-option-height: 32px;
  --select-popup-option--line-height: 16.8px;
  --select-popup-option-font-weight: 400;
  --select-popup-option-font-weight_selected: 500;
  --select-single-popup-option-padding: 8px 12px;
  --select-multi-popup-option-padding-horizontal: 4px;
  --select-popup-option-bg-color-default: var(--color-grayscale-white);
  --select-popup-option-bg-color-hover: var(--select-option-bg-color-hover);
  --select-popup-option-bg-color-selected: var(
    --select-option-bg-color-selected
  );
  --select-popup-option-bg-color-disabled: var(
    --select-option-bg-color-disabled
  );
  --select-popup-option-text-color-default: var(--color-text-primary);
  --select-popup-option-text-color-hover: var(
    --select-option-text-color-hover-selected
  );
  --select-popup-option-text-color-selected: var(
    --select-option-text-color-hover-selected
  );
  --select-popup-option-text-color-disabled: var(
    --select-option-text-color-disabled
  );
  --select-popup-option-hightlight-text-color: var(--color-text-primary);
  --select-popup-option-hightlight-font-weight: 500;

  /* option group title */
  --select-popup-group-title-height: 20px;
  --select-popup-group-title-padding-horizontal: 12px;
  --select-popup-group-title-padding-top: 8px;
  --select-popup-group-title-font-size: 12px;
  --select-popup-group-title-color-text: var(--color-text-primary);
}
