:root {
  --modal-color-mask-bg: rgba(29, 33, 41, 40%);
  --modal-width: 100%;
  --modal-height: 100%;
  --modal-top: 0;
  --modal-left: 0;
  --modal-z-index: 1001;
  --modal-margin: 0 auto;
  --modal-modal-min-width: 520px;
  --modal-modal-max-width: 100%;
  --modal-modal-max-height: 100vh;
  --modal-modal-width: fit-content;
  --modal-modal-border: 0 solid var(--color-border-line-primary);
  --modal-modal-border-radius: 3px;
  --modal-modal-background-color: var(--color-background-primary);
  --modal-modal-line-height: 1.5715;
  --modal-header-padding: 0 20px;
  --modal-header-border-bottom: 1px solid var(--color-border-line-primary);
  --modal-header-height: 48px;
  --modal-title-flex: 1;
  --modal-content-padding: 24px 20px;
  --modal-content-color: var(--color-text-primary);
  --modal-content-font-size: 14px;
  --modal-footer-border-top: 1px solid var(--color-border-line-primary);
  --modal-footer-padding: 16px 20px;
  --modal-footer-margin-left: 10px;
  --modal-closeButton-right: 20px;
  --modal-closeButton-top: 6px;
  --modal-closeButton-font-size: 12px;
}
