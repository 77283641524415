:root {
  /* misc */
  --field-color: var(--color-text-secondary);
  --field-font-size: var(--spacing-14px);
  --field-placeholder-color: var(--color-text-field-placeholder);
  --field-placeholder-color_focus: var(--color-light-text-tertiary);
  --field-color-bg_focus: var(--color-background-secondary);

  /* disabled */
  --field-color-disabled: var(--color-light-text-secondary);
  --field-color-bg-disabled: var(--color-light-text-disabled);
  --field-color-border-disabled: var(--color-light-text-secondary);

  /* read-only */
  --field-color-read-only: var(--color-light-text-secondary);

  /* error */
  --field-color-error: var(--color-text-secondary);

  /* input wrapper */
  --field-input-wrapper-color-border: #dedede;

  /* addons */
  --addon-color: var(--color-text-secondary);
  --addon-bg: transparent;
  --addon-font-size: var(--spacing-14px);

  /* label */
  --label-font-size: 14px;
  --label-color: var(--color-text-secondary);
  --label-color-inset: var(--color-text-secondary);
  --label-font-size-inset: var(--spacing-14px);
  --label-symbol-font-size: 10px;
  --label-symbol-color: var(--color-state-critical);
}
