*:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

:root {
  --section-padding: 1rem;
  --page-gutter: 1rem;
  --product-image-aspect-ratio: 240 / 300;
}

/** Scroll Lock **/
/* stylelint-disable */
.lock-body-scroll {
  overflow-y: hidden !important;
  height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
  max-width: 100%;
}

/* stylelint-enable */

@media (--mobile) {
  .lock-body-scroll {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@media (--tablet) {
  :root {
    --section-padding: 1rem;
    --page-gutter: 2rem;
  }

  .lock-body-scroll {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@media (--desktop) {
  :root {
    --section-padding: 1.5rem;
    --page-gutter: 3rem;
  }

  .lock-body-scroll {
    padding-right: var(--scrollbar-width) !important;
  }
}
