h1 {
  @apply font-bold font-headings text-2xl md:text-4xl;
}

h2 {
  @apply font-bold font-headings text-lg leading-6 md:text-2xl;
}

h3 {
  @apply font-medium font-headings text-base md:text-lg;
}

:root {
  --multicolored: linear-gradient(180deg, #4ade80 0%, #a78bfa 100%);
  --petrol: #216477;
  --oliv: olive;
}
