:root {
  /** Input Text **/
  --input-text-color: var(--color-text-field-active);
  --input-text-font-family: var(--typography-body-font-family-stack);
  --input-text-font-size: var(--typography-body-font-size);
  --input-text-line-height: var(--typography-body-line-height);
  --input-text-text-decoration: var(--typography-body-text-decoration);
  --input-text-font-weight: var(--typography-body-font-weight);
  --input-text-letter-spacing: var(--typography-body-letter-spacing);
  --input-text-color-disabled: var(--color-text-field-active);
  --input-stroke-width: 1px;

  /* Input Borders */
  --input-border-radius: 4px;
  --input-border-width: 1px;
  --input-border-color: var(--color-interactive-action-field);

  /** Input Paddings **/
  --input-padding-horizontal: 16px;
  --input-padding-default: 2px var(--input-padding-horizontal);
  --input-padding-inset: 16px var(--input-padding-horizontal) 0;

  /** Inset padding for child input of the complex input field */
  --input-child-padding-inset: 16px 0 0;

  /* Placeholder */
  --input-placeholder-color: var(--color-text-field-placeholder);
  --input-placeholder-font-family: var(--typography-body-font-family-stack);
  --input-placeholder-font-size: var(--typography-body-font-size);
  --input-placeholder-line-height: var(--typography-body-line-height);
  --input-placeholder-text-decoration: var(--typography-body-text-decoration);
  --input-placeholder-font-weight: var(--typography-body-font-weight);
  --input-placeholder-letter-spacing: var(--typography-body-letter-spacing);
  --input-placeholder-font-size-active: var(--typography-caption-font-size);

  /* Label */
  --input-label-color: var(--color-text-field-placeholder);
  --input-label-font-family: var(--typography-caption-font-family-stack);
  --input-label-font-size: var(--typography-caption-font-size);
  --input-label-line-height: var(--typography-caption-line-height);
  --input-label-text-decoration: var(--typography-caption-text-decoration);
  --input-label-font-weight: var(--typography-caption-font-weight);
  --input-label-letter-spacing: var(--typography-caption-letter-spacing);

  /* Input Background Color */
  --input-bg-color: var(--color-grayscale-white);
  --input-bg-color-hover: var(--color-grayscale-white);
  --input-bg-color-focus: var(--color-grayscale-white);
  --input-bg-color-disabled: var(--color-state-inactive);
  --input-addon-bg-color: var(--color-grayscale-white);

  /* input filled */
  --input-filled-border-color: transparent;
  --input-filled-bg-color: var(--color-background-secondary);
  --input-filled-bg-color-focus: var(--color-background-secondary);

  /* text color */
  --input-border-addon-separator-width: 1px;
  --input-color-placeholder-text: var(--color-text-field-placeholder);
  --input-color-text_disabled: var(--color-text-secondary);

  /* Default front and label text color */
  --input-color-addon-text: var(--color-text-primary);

  /* textarea  color */
  --textarea-color-tip-text: var(--color-text-field-placeholder);

  /* States */
  --input-color-bg-error: var(--color-grayscale-white);
  --input-color-bg-error-hover: var(--color-grayscale-white);
  --input-color-bg-error-focus: var(--color-grayscale-white);
  --input-border-color-error: var(--color-state-critical);
  --input-border-color-success: var(--color-state-success);
  --input-border-color-warning: var(--color-state-warning);

  /* Sizes */
  --input-size-xs-height: 36px;
  --input-size-sm-height: 42px;
  --input-size-md-height: 48px;
  --input-size-lg-height: 50px;

  /* input search */

  /* textarea */
  --textarea-padding-horizontal: 12px;
  --textarea-padding-vertical: 14px;
  --textarea-inset-active-top: 10px;
  --textarea-min-height: 46px;
  --textarea-tip-font-size: 12px;
  --textarea-limit-bottom-position: 10px;
  --textarea-limit-right-position: var(--textarea-padding-horizontal);

  /* password */
  --password-eye-icon-color: var(--color-text-primary);
  --password-eye-icon-size: 12px;
}
