:root {
  --pagination-font-size: var(--typography-body-medium-font-size);
  --pagination-font-weight: var(--typography-body-medium-font-weight);
  --pagination-line-height: var(--typography-body-medium-line-height);
  --pagination-letter-spacing: var(--typography-body-medium-letter-spacing);
  --pagination-font-family: var(--typography-body-font-family-stack);
  --pagination-text-color: var(--color-text-secondary);
  --pagination-text-color-hover: var(--color-interactive-action-primary);
  --pagination-text-color-active: var(--color-grayscale-white);
  --pagination-bg-active: var(--color-interactive-action-primary);
  --pagination-arrow-border-color: var(--color-interactive-action-secondary);
  --pagination-width: 32px;
  --pagination-height: 32px;
  --pagination-border-radius: 3px;
  --pagination-border-color: transparent;
  --pagination-border-color-hover: var(--color-interactive-action-primary);
  --pagination-border-color-active: var(--color-interactive-action-primary);
  --pagination-disabled-border: 1px solid var(--color-grayscale-mid-grey);
}
