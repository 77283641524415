:global(html.js) .image:not(.priority) {
  opacity: 1;

  &.fade {
    transition: opacity 0.25s ease;

    &:not(.loaded) {
      opacity: 0;
    }
  }
}
