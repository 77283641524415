:root {
  --color-mask-bg: rgba(29, 33, 41, 40%);
  --drawer-width: 100%;
  --drawer-height: 100%;
  --drawer-top: 0;
  --drawer-left: 0;
  --drawer-z-index: 1001;
  --drawer-background-color: var(--color-background-primary);

  /* Drawer */

  --drawer-border-radius: 16px;
  --drawer-bottom-height: 75vh;

  /** Drawer Header **/
  --drawer-header-height: 48px;
  --drawer-header-padding: 0 16px;
  --drawer-header-border-bottom: 1px solid var(--color-border-primary);

  /** Drawer Content **/
  --drawer-content-flex: 1;
  --drawer-content-padding: 12px 16px;

  /** Drawer Footer **/
  --drawer-footer-padding: 16px;
  --drawer-footer-btn-padding: 16px 48.5px;
  --drawer-footer-border-top: 1px solid var(--color-border-primary);
  --drawer-footer-button-margin-right: 10px;

  /** Drawer Close Button **/
  --drawer-close-button-right: 5px;
  --drawer-close-button-top: 10px;
  --drawer-closeButton-font-size: 12px;
}
