:root {
  /** Fallback font stacks **/
  --typography-heading-fallback-font-stack: -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue',
    sans-serif;
  --typography-body-fallback-font-stack: -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue',
    sans-serif;
  --typography-display-fallback-font-stack: -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue',
    sans-serif;

  /** Body **/
  --typography-body-font-weight: var(--typography-body-regular-font-weight);
  --typography-body-text-transform: var(--typography-body-regular-text-case);
  --typography-body-font-size: var(--typography-body-regular-font-size);
  --typography-body-line-height: var(--typography-body-regular-line-height);
  --typography-body-text-decoration: var(
    --typography-body-regular-text-decoration
  );
  --typography-body-font-family-stack: var(
      --typography-body-regular-font-family
    ),
    var(--typography-body-fallback-font-stack);
  --typography-body-font-style: var(--typography-body-regular-font-style);
  --typography-body-font-stretch: var(--typography-body-regular-font-stretch);
  --typography-body-letter-spacing: var(
    --typography-body-regular-letter-spacing
  );
  --typography-body-text-indent: var(
    --typography-body-regular-paragraph-indent
  );
  --typography-body-word-spacing: var(
    --typography-body-regular-paragraph-spacing
  );
  --typography-body-color-inverted: #fff;
  --typography-body-light-font-weight: 300;

  /** BodyLarge **/
  --typography-bodyLarge-text-transform: var(
    --typography-body-large-regular-text-case
  );
  --typography-bodyLarge-font-size: var(
    --typography-body-large-regular-font-size
  );
  --typography-bodyLarge-line-height: var(
    --typography-body-large-regular-line-height
  );
  --typography-bodyLarge-text-decoration: var(
    --typography-body-large-regular-text-decoration
  );
  --typography-bodyLarge-font-family-stack: var(
      --typography-body-large-regular-font-family
    ),
    var(--typography-body-fallback-font-stack);
  --typography-bodyLarge-font-weight: var(
    --typography-body-large-regular-font-weight
  );
  --typography-bodyLarge-font-style: var(
    --typography-body-large-regular-font-style
  );
  --typography-bodyLarge-letter-spacing: var(
    --typography-body-large-regular-letter-spacing
  );

  /** Button **/
  --typography-button-text-transform: var(--typography-button-text-case);

  /* --typography-button-font-size: var(--typography-button-font-size); */

  /* --typography-button-line-height: var(--typography-button-line-height); */

  /* --typography-button-text-decoration: var(--typography-button-text-decoration); */
  --typography-button-font-family-stack: var(--typography-button-font-family),
    var(--typography-body-fallback-font-stack);

  /* --typography-button-font-weight: var(--typography-button-font-weight); */

  /* --typography-button-font-style: var(--typography-button-font-style); */

  /* --typography-button-letter-spacing: var(--typography-button-letter-spacing); */

  /** Label **/
  --typography-label-text-transform: var(--typography-label-text-case);

  /* --typography-label-font-size: var(--typography-label-font-size); */

  /* --typography-label-line-height: var(--typography-label-line-height); */

  /* --typography-label-text-decoration: var(--typography-label-text-decoration); */
  --typography-label-font-family-stack: var(--typography-label-font-family),
    var(--typography-body-fallback-font-stack);

  /* --typography-label-font-weight: var(--typography-label-font-weight); */

  /* --typography-label-font-style: var(--typography-label-font-style); */

  /* --typography-label-letter-spacing: var(--typography-label-letter-spacing); */

  /** Caption **/
  --typography-caption-text-transform: var(--typography-body-caption-text-case);
  --typography-caption-font-size: var(--typography-body-caption-font-size);
  --typography-caption-line-height: var(--typography-body-caption-line-height);
  --typography-caption-text-decoration: var(
    --typography-body-caption-text-decoration
  );
  --typography-caption-font-family-stack: var(
      --typography-body-caption-font-family
    ),
    var(--typography-body-fallback-font-stack);
  --typography-caption-font-weight: var(--typography-body-caption-font-weight);
  --typography-caption-font-style: var(--typography-body-caption-font-style);
  --typography-caption-letter-spacing: var(
    --typography-body-caption-letter-spacing
  );

  /** Page heading **/
  --typography-pageheading-text-transform: var(
    --typography-page-heading-mobile-text-case
  );
  --typography-pageheading-font-size: var(
    --typography-page-heading-mobile-font-size
  );
  --typography-pageheading-line-height: var(
    --typography-page-heading-mobile-line-height
  );
  --typography-pageheading-text-decoration: var(
    --typography-page-heading-mobile-text-decoration
  );
  --typography-pageheading-font-family-stack: var(
      --typography-page-heading-mobile-font-family
    ),
    var(--typography-heading-fallback-font-stack);
  --typography-pageheading-font-weight: var(
    --typography-page-heading-mobile-font-weight
  );
  --typography-pageheading-font-style: var(
    --typography-page-heading-mobile-font-style
  );
  --typography-pageheading-letter-spacing: var(
    --typography-page-heading-mobile-letter-spacing
  );

  /** Heading **/
  --typography-heading-text-transform: var(
    --typography-heading-mobile-text-case
  );
  --typography-heading-font-size: var(--typography-heading-mobile-font-size);
  --typography-heading-line-height: var(
    --typography-heading-mobile-line-height
  );
  --typography-heading-text-decoration: var(
    --typography-heading-mobile-text-decoration
  );
  --typography-heading-font-family-stack: var(
      --typography-heading-mobile-font-family
    ),
    var(--typography-heading-fallback-font-stack);
  --typography-heading-font-weight: var(
    --typography-heading-mobile-font-weight
  );
  --typography-heading-font-style: var(--typography-heading-mobile-font-style);
  --typography-heading-letter-spacing: var(
    --typography-heading-mobile-letter-spacing
  );

  /** Subheading **/
  --typography-subheading-text-transform: var(
    --typography-subheading-regular-mobile-text-case
  );
  --typography-subheading-font-size: var(
    --typography-subheading-regular-mobile-font-size
  );
  --typography-subheading-line-height: var(
    --typography-subheading-regular-mobile-line-height
  );
  --typography-subheading-text-decoration: var(
    --typography-subheading-regular-mobile-text-decoration
  );
  --typography-subheading-font-family-stack: var(
      --typography-subheading-regular-mobile-font-family
    ),
    var(--typography-heading-fallback-font-stack);
  --typography-subheading-font-weight-regular: var(
    --typography-subheading-regular-mobile-font-weight
  );
  --typography-subheading-font-weight-bold: var(
    --typography-subheading-heavy-mobile-font-weight
  );
  --typography-subheading-font-style: var(
    --typography-subheading-regular-mobile-font-style
  );
  --typography-subheading-letter-spacing: var(
    --typography-subheading-regular-mobile-letter-spacing
  );

  /** xLarge **/
  --typography-xLarge-text-transform: var(
    --typography-display-x-large-mobile-text-case
  );
  --typography-xLarge-font-size: var(
    --typography-display-x-large-mobile-font-size
  );
  --typography-xLarge-line-height: var(
    --typography-display-x-large-mobile-line-height
  );
  --typography-xLarge-text-decoration: var(
    --typography-display-x-large-mobile-text-decoration
  );
  --typography-xLarge-font-family-stack: var(
      --typography-display-x-large-mobile-font-family
    ),
    var(--typography-display-fallback-font-stack);
  --typography-xLarge-font-weight: var(
    --typography-display-x-large-mobile-font-weight
  );
  --typography-xLarge-font-style: var(
    --typography-display-x-large-mobile-font-style
  );
  --typography-xLarge-letter-spacing: var(
    --typography-display-x-large-mobile-letter-spacing
  );

  /** Large **/
  --typography-large-text-transform: var(
    --typography-display-large-mobile-text-case
  );
  --typography-large-font-size: var(
    --typography-display-large-mobile-font-size
  );
  --typography-large-line-height: var(
    --typography-display-large-mobile-line-height
  );
  --typography-large-text-decoration: var(
    --typography-display-large-mobile-text-decoration
  );
  --typography-large-font-family-stack: var(
      --typography-display-large-mobile-font-family
    ),
    var(--typography-display-fallback-font-stack);
  --typography-large-font-weight: var(
    --typography-display-large-mobile-font-weight
  );
  --typography-large-font-style: var(
    --typography-display-large-mobile-font-style
  );
  --typography-large-letter-spacing: var(
    --typography-display-large-mobile-letter-spacing
  );

  /** Medium **/
  --typography-medium-text-transform: var(
    --typography-display-medium-mobile-text-case
  );
  --typography-medium-font-size: var(
    --typography-display-medium-mobile-font-size
  );
  --typography-medium-line-height: var(
    --typography-display-medium-mobile-line-height
  );
  --typography-medium-text-decoration: var(
    --typography-display-medium-mobile-text-decoration
  );
  --typography-medium-font-family-stack: var(
      --typography-display-medium-mobile-font-family
    ),
    var(--typography-display-fallback-font-stack);
  --typography-medium-font-weight: var(
    --typography-display-medium-mobile-font-weight
  );
  --typography-medium-font-style: var(
    --typography-display-medium-mobile-font-style
  );
  --typography-medium-letter-spacing: var(
    --typography-display-medium-mobile-letter-spacing
  );

  /** Small **/
  --typography-small-text-transform: var(
    --typography-display-small-mobile-text-case
  );
  --typography-small-font-size: var(
    --typography-display-small-mobile-font-size
  );
  --typography-small-line-height: var(
    --typography-display-small-mobile-line-height
  );
  --typography-small-text-decoration: var(
    --typography-display-small-mobile-text-decoration
  );
  --typography-small-font-family-stack: var(
      --typography-display-small-mobile-font-family
    ),
    var(--typography-display-fallback-font-stack);
  --typography-small-font-weight: var(
    --typography-display-small-mobile-font-weight
  );
  --typography-small-font-style: var(
    --typography-display-small-mobile-font-style
  );
  --typography-small-letter-spacing: var(
    --typography-display-small-mobile-letter-spacing
  );

  /** XS Small **/
  --typography-xsmall-text-transform: var(
    --typography-display-xs-small-mobile-text-case
  );
  --typography-xsmall-font-size: var(
    --typography-display-xs-small-mobile-font-size
  );
  --typography-xsmall-line-height: var(
    --typography-display-xs-small-mobile-line-height
  );
  --typography-xsmall-text-decoration: var(
    --typography-display-xs-small-mobile-text-decoration
  );
  --typography-xsmall-font-family-stack: var(
      --typography-display-xs-small-mobile-font-family
    ),
    var(--typography-display-fallback-font-stack);
  --typography-xsmall-font-weight: var(
    --typography-display-xs-small-mobile-font-weight
  );
  --typography-xsmall-font-style: var(
    --typography-display-xs-small-mobile-font-style
  );
  --typography-xsmall-letter-spacing: var(
    --typography-display-xs-small-mobile-letter-spacing
  );
}

/** min 767px **/
@media (min-width: 767px) {
  :root {
    /** Page heading **/
    --typography-pageheading-text-transform: var(
      --typography-page-heading-desktop-text-case
    );
    --typography-pageheading-font-size: var(
      --typography-page-heading-desktop-font-size
    );
    --typography-pageheading-line-height: var(
      --typography-page-heading-desktop-line-height
    );
    --typography-pageheading-text-decoration: var(
      --typography-page-heading-desktop-text-decoration
    );
    --typography-pageheading-font-family-stack: var(
        --typography-page-heading-desktop-font-family
      ),
      var(--typography-heading-fallback-font-stack);
    --typography-pageheading-font-weight: var(
      --typography-page-heading-desktop-font-weight
    );
    --typography-pageheading-font-style: var(
      --typography-page-heading-desktop-font-style
    );
    --typography-pageheading-letter-spacing: var(
      --typography-page-heading-desktop-letter-spacing
    );

    /** Heading **/
    --typography-heading-text-transform: var(
      --typography-heading-desktop-text-case
    );
    --typography-heading-font-size: var(--typography-heading-desktop-font-size);
    --typography-heading-line-height: var(
      --typography-heading-desktop-line-height
    );
    --typography-heading-text-decoration: var(
      --typography-heading-desktop-text-decoration
    );
    --typography-heading-font-family-stack: var(
        --typography-heading-desktop-font-family
      ),
      var(--typography-heading-fallback-font-stack);
    --typography-heading-font-weight: var(
      --typography-heading-desktop-font-weight
    );
    --typography-heading-font-style: var(
      --typography-heading-desktop-font-style
    );
    --typography-heading-letter-spacing: var(
      --typography-heading-desktop-letter-spacing
    );

    /** Subheading **/
    --typography-subheading-text-transform: var(
      --typography-subheading-regular-desktop-text-case
    );
    --typography-subheading-font-size: var(
      --typography-subheading-regular-desktop-font-size
    );
    --typography-subheading-line-height: var(
      --typography-subheading-regular-desktop-line-height
    );
    --typography-subheading-text-decoration: var(
      --typography-subheading-regular-desktop-text-decoration
    );
    --typography-subheading-font-family-stack: var(
        --typography-subheading-regular-desktop-font-family
      ),
      var(--typography-heading-fallback-font-stack);
    --typography-subheading-font-weight-regular: var(
      --typography-subheading-regular-desktop-font-weight
    );
    --typography-subheading-font-weight-bold: var(
      --typography-subheading-heavy-desktop-font-weight
    );
    --typography-subheading-font-style: var(
      --typography-subheading-regular-desktop-font-style
    );
    --typography-subheading-letter-spacing: var(
      --typography-subheading-regular-desktop-letter-spacing
    );

    /** xLarge **/
    --typography-xLarge-text-transform: var(
      --typography-display-x-large-desktop-text-case
    );
    --typography-xLarge-font-size: var(
      --typography-display-x-large-desktop-font-size
    );
    --typography-xLarge-line-height: var(
      --typography-display-x-large-desktop-line-height
    );
    --typography-xLarge-text-decoration: var(
      --typography-display-x-large-desktop-text-decoration
    );
    --typography-xLarge-font-family-stack: var(
        --typography-display-x-large-desktop-font-family
      ),
      var(--typography-display-fallback-font-stack);
    --typography-xLarge-font-weight: var(
      --typography-display-x-large-desktop-font-weight
    );
    --typography-xLarge-font-style: var(
      --typography-display-x-large-desktop-font-style
    );
    --typography-xLarge-letter-spacing: var(
      --typography-display-x-large-desktop-letter-spacing
    );

    /** Large **/
    --typography-large-text-transform: var(
      --typography-display-large-desktop-text-case
    );
    --typography-large-font-size: var(
      --typography-display-large-desktop-font-size
    );
    --typography-large-line-height: var(
      --typography-display-large-desktop-line-height
    );
    --typography-large-text-decoration: var(
      --typography-display-large-desktop-text-decoration
    );
    --typography-large-font-family-stack: var(
        --typography-display-large-desktop-font-family
      ),
      var(--typography-display-fallback-font-stack);
    --typography-large-font-weight: var(
      --typography-display-large-desktop-font-weight
    );
    --typography-large-font-style: var(
      --typography-display-large-desktop-font-style
    );
    --typography-large-letter-spacing: var(
      --typography-display-large-desktop-letter-spacing
    );

    /** Medium **/
    --typography-medium-text-transform: var(
      --typography-display-medium-desktop-text-case
    );
    --typography-medium-font-size: var(
      --typography-display-medium-desktop-font-size
    );
    --typography-medium-line-height: var(
      --typography-display-medium-desktop-line-height
    );
    --typography-medium-text-decoration: var(
      --typography-display-medium-desktop-text-decoration
    );
    --typography-medium-font-family-stack: var(
        --typography-display-medium-desktop-font-family
      ),
      var(--typography-display-fallback-font-stack);
    --typography-medium-font-weight: var(
      --typography-display-medium-desktop-font-weight
    );
    --typography-medium-font-style: var(
      --typography-display-medium-desktop-font-style
    );
    --typography-medium-letter-spacing: var(
      --typography-display-medium-desktop-letter-spacing
    );

    /** Small **/
    --typography-small-text-transform: var(
      --typography-display-small-desktop-text-case
    );
    --typography-small-font-size: var(
      --typography-display-small-desktop-font-size
    );
    --typography-small-line-height: var(
      --typography-display-small-desktop-line-height
    );
    --typography-small-text-decoration: var(
      --typography-display-small-desktop-text-decoration
    );
    --typography-small-font-family-stack: var(
        --typography-display-small-desktop-font-family
      ),
      var(--typography-display-fallback-font-stack);
    --typography-small-font-weight: var(
      --typography-display-small-desktop-font-weight
    );
    --typography-small-font-style: var(
      --typography-display-small-desktop-font-style
    );
    --typography-small-letter-spacing: var(
      --typography-display-small-desktop-letter-spacing
    );

    /** XS Small **/
    --typography-xsmall-text-transform: var(
      --typography-display-xs-small-desktop-text-case
    );
    --typography-xsmall-font-size: var(
      --typography-display-xs-small-desktop-font-size
    );
    --typography-xsmall-line-height: var(
      --typography-display-xs-small-desktop-line-height
    );
    --typography-xsmall-text-decoration: var(
      --typography-display-xs-small-desktop-text-decoration
    );
    --typography-xsmall-font-family-stack: var(
        --typography-display-xs-small-desktop-font-family
      ),
      var(--typography-display-fallback-font-stack);
    --typography-xsmall-font-weight: var(
      --typography-display-xs-small-desktop-font-weight
    );
    --typography-xsmall-font-style: var(
      --typography-display-xs-small-desktop-font-style
    );
    --typography-xsmall-letter-spacing: var(
      --typography-display-xs-small-desktop-letter-spacing
    );
  }
}
