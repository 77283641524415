:root {
  /* track */
  --slider-track-width: 2px;
  --slider-track-bg-color: var(--color-border-line-primary);
  --slider-track-bg-color-disabled: var(--color-state-inactive);

  /* bar */
  --slider-bar-bg-color: var(--color-interactive-action-primary);
  --slider-bar-bg-color-disabled: var(--color-state-inactive);

  /* button */
  --slider-button-bg-color: var(--color-grayscale-white);
  --slider-button-border-size: 2px;
  --slider-button-border-color: var(--color-interactive-action-primary);
  --slider-button-border-color-disabled: var(--color-state-inactive);
  --slider-button-shadow-active: 0 2px 5px rgb(0 0 0 / 10%);
  --slider-button-width: 12px;
  --slider-button-width-active: 14px;

  /* dot */
  --slider-dot-bg-color: var(--color-grayscale-white);
  --slider-dot-border-width: 50%;
  --slider-dot-font-size: 12px;
  --slider-dot-width: 8px;

  /* mark */
  --slider-with-mark-margin-bottom: 24px;
  --slider-mark-padding: 20px;
  --slider-mark-padding-vertical: 20px;
  --slider-mark-font-size: 14px;
  --slider-mark-font-color: var(--color-text-primary);

  /* tick */
  --slider-tick-width: 1px;
  --slider-tick-height: 3px;

  /* vertical */
  --slider-height-vertical: 200px;
  --slider-mark-left-spacing: 3px;
}
