:root {
  --to-top-position-bottom: 20px;
  --to-top-position-right: 20px;
  --to-top-position-let: auto;
  --to-top-z-index: 100;

  /** Back to top button **/
  --to-top-button-padding: 12px;
  --to-top-button-with-label-padding: 6px 8px;
  --to-top-button-bg-color: var(--color-interactive-action-primary);
  --to-top-button-bg-color-hover: var(--color-interactive-action-primary-hover);
  --to-top-button-color: var(--color-grayscale-white);
  --to-top-button-with-label-border-radius: 4px;
  --to-top-button-border-radius: 50%;
  --to-top-button-font-size: var(--typography-label-font-size);
  --to-top-button-text-decoration: var(--typography-label-text-decoration);
  --to-top-button-font-family: var(--typography-label-font-family-stack);
  --to-top-button-icon-size: 18px;
  --to-top-button-icon-margin: 0 5px 0 0;
}
