:root {
  --alert-border-width: 1px;
  --alert-border-radius: 2px;
  --alert-padding: 22px;
  --alert-shadow: none;
  --alert-icon-size: 18px;
  --alert-title-font-size: var(--typography-body-font-size);
  --alert-title-line-height: var(--typography-body-line-height);
  --alert-title-letter-spacing: var(--typography-body-letter-spacing);
  --alert-title-font-weight: 600;
  --alert-title-margin-bottom: 6px;
  --alert-text-font-size: var(--typography-body-font-size);
  --alert-text-line-height: var(--typography-body-line-height);
  --alert-text-font-weight: var(--typography-body-font-weight);
  --alert-text-letter-spacing: var(--typography-body-letter-spacing);
  --alert-close-button-color: var(--color-text-primary);
  --alert-close-button-color-hover: var(--color-text-primary);
  --alert-close-button-margin-left: 8px;
  --alert-close-button-icon-size: 16px;
  --alert-icon-margin-right: 8px;
  --alert-action-margin: 0 8px;

  /*****************************************************
 * type: default / warning / error /success
 *****************************************************/
  --alert-default-color-bg: var(--color-grayscale-lightgrey);
  --alert-default-color-border: transparent;
  --alert-default-icon-color: var(--color-text-primary);
  --alert-default-title-color: var(--color-text-primary);
  --alert-default-text-color: var(--color-text-secondary);
  --alert-warning-color-bg: var(--color-state-warning);
  --alert-warning-color-border: transparent;
  --alert-warning-icon-color: #fd7e14;
  --alert-warning-title-color: var(--color-text-primary);
  --alert-warning-text-color: var(--color-text-secondary);
  --alert-error-color-bg: var(--color-state-critical-light);
  --alert-error-color-border: transparent;
  --alert-error-icon-color: var(--color-state-critical);
  --alert-error-title-color: var(--color-text-primary);
  --alert-error-text-color: var(--color-text-secondary);
  --alert-success-color-bg: var(--color-state-success-light);
  --alert-success-color-border: transparent;
  --alert-success-icon-color: var(--color-state-success);
  --alert-success-title-color: var(--color-text-primary);
  --alert-success-text-color: var(--color-text-secondary);
}
