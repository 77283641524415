:root {
  --tabs-nav-item-padding: 15px 22px;
  --tabs-nav-item-min-width: 125px;
  --tabs-nav-item-border-color: var(--color-interactive-action-secondary);
  --tabs-nav-item-text-color: var(--color-text-secondary);
  --tabs-nav-item-text-color-pressed: var(--color-text-primary);
  --tabs-nav-item-text-color-active-hover: var(--color-text-action-link-hover);
  --tabs-nav-item-text-color-active: var(--color-text-action-link);
  --tabs-card-nav-item-bg-color-active: var(--color-interactive-action-primary);
  --tabs-card-nav-item-text-color-active: var(--color-grayscale-white);
  --tabs-card-nav-item-border-color-active: var(
    --color-interactive-action-primary
  );
  --tabs-line-size-header-border: 2px;
  --tabs-line-color-header-border: var(--color-border-line-primary);
  --tabs-content-padding: 10px;
  --tabs-content-min-height: 125px;
  --tabs-content-font-size: 14px;
  --tabs-content-border-color: var(--color-interactive-action-secondary);
}
