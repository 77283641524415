:root {
  --avatar-default-bg: var(--color-text-accent-01);
  --avatar-text-color: var(--color-grayscale-white);
  --avatar-text-font-family: var(--typography-body-font-family-stack);
  --avatar-text-letter-spacing: var(--typography-body-letter-spacing);
  --avatar-text-font-size: var(--typography-body-font-size);
  --avatar-text-line-height: var(--typography-body-line-height);
  --avatar-text-font-weight: var(--typography-body-font-weight);

  /** Avatar Sizes */
  --avatar-size-xs: 30px;
  --avatar-size-sm: 36px;
  --avatar-size-md: 42px;
  --avatar-size-lg: 48px;
  --avatar-size-xl: 52px;

  /** Avatar Variants */
  --avatar-circle-border-radius: 50%;
  --avatar-square-border-radius: 5px;

  /** Avatar Group */
  --avatar-group-item-border: var(--color-grayscale-white);
  --avatar-group-item-border-width: 1px;
  --avatar-group-item-margin-left: -10px;

  /** Avatar Max Count */
  --avatar-max-count-font-size: var(--typography-body-font-size);
  --avatar-max-count-text-color: var(--color-grayscale-white);
}
