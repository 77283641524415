:root {
  --easing-productive: cubic-bezier(0.22, 0.61, 0.36, 1);
  --easing-focus: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  --easing-expressive: cubic-bezier(0.58, 0.01, 0.15, 1.5);
  --transition-timing-function-linear: cubic-bezier(0, 0, 1, 1);
  --transition-timing-function-standard: cubic-bezier(0.34, 0.69, 0.1, 1);
  --transition-timing-function-overshoot: cubic-bezier(0.3, 1.3, 0.3, 1);
  --transition-timing-function-decelerate: cubic-bezier(0.4, 0.8, 0.74, 1);
  --transition-timing-function-accelerate: cubic-bezier(0.26, 0, 0.6, 0.2);
  --transition-duration-1: 0.1s;
  --transition-duration-2: 0.2s;
  --transition-duration-3: 0.3s;
  --transition-duration-4: 0.4s;
  --transition-duration-5: 0.5s;
  --transition-duration-loading: 1s;
}
