.root {
  width: var(--notification-size-md);
}

.default {
  border: 1px solid var(--greyscale-900, #212121);
  background: var(--greyscale-900, #212121);
  color: white;
}

.primary {
  border: 1px solid var(--color-text-primary-blue, #0c254c);
  background: var(--color-text-primary-blue, #0c254c);
  color: white;
}

.info {
  border: 1px solid var(--primary-cyan-500, #009fe3);
  background: var(--primary-cyan-500, #009fe3);
  color: white;
}

.error {
  border: 1px solid var(--color-brand-secondary, #f6625a);
  background: var(--color-brand-secondary, #f6625a);
  color: white;
}

.success {
  border: 1px solid var(--color-state-success, #95c11f);
  background: var(--color-state-success, #95c11f);
  color: white;
}

.button {
  border: 0;
  outline: 0;
  padding: 0;

  --btn-ghost-color-text-primary: white;
  --btn-icon-size: 16px;
  --btn-size-md-height: 'auto';
}
