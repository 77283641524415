:root {
  --tag-border-width: 1px;
  --tag-border-type: solid;
  --tag-padding-horizontal: 8px;
  --tag-padding-vertical: 0;
  --tag-icon-margin-right: 8px;
  --tag-text-font-weight: var(--typography-body-medium-bold-font-weight);
  --tag-text-font-family: var(--typography-body-font-family-stack);
  --tag-text-font-size: 14px;
  --tag-border-radius: 4px;
  --tag-size-small: 20px;
  --tag-size-default: 22px;
  --tag-size-medium: 26px;
  --tag-size-large: 30px;
  --tag-size-small-font-size: var(--typography-body-caption-font-size);
  --tag-size-default-font-size: var(--typography-body-caption-font-size);
  --tag-size-medium-font-size: var(--typography-body-caption-font-size);
  --tag-size-large-font-size: var(--typography-body-regular-font-size);
  --tag-color-bg-not-checked_hover: var(--color-interactive-action-field);
  --tag-custom-color-text: var(--color-text-primary);
  --tag-custom-color-icon-bg_hover: var(--color-interactive-action-focus);
  --tag-close-btn-hover-color: #e1e1e1;

  /* Default Color */
  --tag-default-color-bg: var(--color-grayscale-lightgrey);
  --tag-default-color-bg_hover: var(--color-interactive-action-focus);
  --tag-default-color-icon: var(--color-text-primary);
  --tag-default-color-text: var(--color-brand-primary);
  --tag-default-color-border: transparent;
  --tag-default-bordered-color-border: var(--color-border-line-primary);
  --tag-default-color-border_hover: transparent;

  /* Critical Color */
  --tag-critical-color-bg: var(--color-state-critical-light);
  --tag-critical-color-bg_hover: var(--color-state-critical-light);
  --tag-critical-color-border: transparent;
  --tag-critical-bordered-color-border: var(--color-state-critical-light);
  --tag-critical-bordered-bg-color: var(--color-grayscale-white);
  --tag-critical-color-border_hover: transparent;
  --tag-critical-color-icon: var(--color-state-critical-light);
  --tag-critical-color-icon-bg_hover: var(--color-state-critical-light);
  --tag-critical-color-text: var(--color-state-critical-light);

  /* Warning Color */
  --tag-warning-color-bg: var(--color-state-warning-light);
  --tag-warning-color-bg_hover: var(--color-state-warning-light-hover);
  --tag-warning-color-border: transparent;
  --tag-warning-bordered-color-border: var(--color-state-warning);
  --tag-warning-color-border_hover: transparent;
  --tag-warning-color-icon: var(--color-state-warning);
  --tag-warning-color-icon-bg_hover: var(--color-state-warning-light);
  --tag-warning-color-text: var(--color-state-warning);

  /* Success Color */
  --tag-success-color-bg: var(--color-state-success);
  --tag-success-color-bg_hover: var(--color-state-success-light-hover);
  --tag-success-color-border: transparent;
  --tag-success-bordered-color-border: var(--color-state-success);
  --tag-success-bordered-bg-color: var(--color-grayscale-white);
  --tag-success-color-border_hover: transparent;
  --tag-success-color-icon: var(--color-state-success);
  --tag-success-color-icon-bg_hover: var(--color-state-success-light);
  --tag-success-color-text: var(--color-grayscale-white);

  /* Pending Color */
  --tag-pending-color-bg: var(--color-state-pending-light);
  --tag-pending-color-bg_hover: var(--color-state-pending-light-hover);
  --tag-pending-color-border: transparent;
  --tag-pending-bordered-color-border: var(--color-state-pending);
  --tag-pending-color-border_hover: transparent;
  --tag-pending-color-icon: var(--color-state-pending);
  --tag-pending-color-icon-bg_hover: var(--color-state-pending-light);
  --tag-pending-color-text: var(--color-state-pending);

  /* Info Color */
  --tag-info-color-bg: var(--color-state-info-light);
  --tag-info-color-bg_hover: var(--color-state-info-light-hover);
  --tag-info-color-border: transparent;
  --tag-info-bordered-color-border: var(--color-state-info);
  --tag-info-color-border_hover: transparent;
  --tag-info-color-icon: var(--color-state-info);
  --tag-info-color-icon-bg_hover: var(--color-state-info-light);
  --tag-info-color-text: var(--color-state-info);
  --tag-disabled-color-bg: var(--color-state-disabled-light);
  --tag-disabled-color-bg_hover: var(--color-state-disabled-light);
  --tag-disabled-color-border: transparent;
  --tag-disabled-bordered-color-border: var(--color-state-disabled);
  --tag-disabled-color-border_hover: transparent;
  --tag-disabled-color-icon: var(--color-state-disabled);
  --tag-disabled-color-icon-bg_hover: var(--color-state-disabled-light);
  --tag-disabled-color-text: var(--color-state-disabled-light);
}
