:root {
  /* misc */
  --vendor-button-border-radius: 3px;
  --vendor-button-margin: 0;
  --vendor-button-padding: 8px 22px;
  --vendor-button-min-width: 297px;
  --vendor-button-min-height: 48px;

  /* button label */
  --vendor-button-label-font-size: 14px;
  --vendor-button-label-line-height: 14px;
  --vendor-button-label-letter-spacing: 1px;
  --vendor-button-label-font-weight: 500;
  --vendor-button-label-font-family: var(--typography-body-regular-font-family);
  --vendor-button-label-text-decoration: none;
  --vendor-button-label-text-transform: none;
}
