:root {
  --shadow-100: 0 0.1px 0.3px rgba(0, 0, 0, 2.2%),
    0 0.1px 0.7px rgba(0, 0, 0, 3.2%), 0 0.3px 1.3px rgba(0, 0, 0, 4%),
    0 0.4px 2.2px rgba(0, 0, 0, 4.8%), 0 0.8px 4.2px rgba(0, 0, 0, 5.8%),
    0 2px 10px rgba(0, 0, 0, 8%);
  --shadow-200: 0 4px 6px rgba(0, 0, 0, 10%);
  --shadow-300: 0 0.2px 0.6px rgba(0, 0, 0, 2%),
    0 0.5px 1.3px rgba(0, 0, 0, 2.8%), 0 0.9px 2.5px rgba(0, 0, 0, 3.5%),
    0 1.6px 4.5px rgba(0, 0, 0, 4.2%), 0 2.9px 8.4px rgba(0, 0, 0, 5%),
    0 7px 20px rgba(0, 0, 0, 7%);
  --shadow-400: 0 0.3px 1.1px rgba(0, 0, 0, 1.7%),
    0 0.7px 2.7px rgba(0, 0, 0, 2.4%), 0 1.3px 5px rgba(0, 0, 0, 3%),
    0 2.2px 8.9px rgba(0, 0, 0, 3.6%), 0 4.2px 16.7px rgba(0, 0, 0, 4.3%),
    0 10px 40px rgba(0, 0, 0, 6%);
  --shadow-500: 0 1.1px 2.2px -5px rgba(0, 0, 0, 2.2%),
    0 2.7px 5.3px -5px rgba(0, 0, 0, 3.2%), 0 5px 10px -5px rgba(0, 0, 0, 4%),
    0 8.9px 17.9px -5px rgba(0, 0, 0, 4.8%),
    0 16.7px 33.4px -5px rgba(0, 0, 0, 5.8%), 0 40px 80px -5px rgba(0, 0, 0, 8%);
}
