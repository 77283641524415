:root {
  --accordion-border-width: 1px;
  --accordion-border-radius: 4px;
  --accordion-border-color: var(--color-border-line-seperate-primary);
  --accordion-title-line-height: var(--typography-body-regular-line-height);
  --accordion-title-font-size: var(--typography-body-regular-font-size);
  --accordion-title-padding-horizontal: var(
    --accordion-title-horizontal-padding
  );
  --accordion-title-padding-vertical: var(--accordion-title-vertical-padding);
  --accordion-title-color-bg: var(--color-grayscale-white);
  --accordion-title-color-bg-hover: var(--color-background-secondary);
  --accordion-title-color-bg-active: var(--color-grayscale-white);
  --accordion-title-color-bg-disabled: var(--color-state-inactive);
  --accordion-title-color-text: var(--color-text-primary);
  --accordion-title-color-text-disabled: var(--accordion-title-color-disabled);
  --accordion-title-color-focus-outline: var(
    --color-interactive-action-primary
  );

  /*
  * Accordion Content
  */
  --accordion-content-color-text: var(--color-text-secondary);
  --accordion-content-color-text_disabled: var(--color-text-secondary);
  --accordion-content-font-size: var(--typography-body-caption-font-size);
  --accordion-content-line-height: var(--typography-body-caption-line-height);
  --accordion-content-padding: 15px 25px 20px 15px;
  --accordion-content-color-bg: var(--color-grayscale-white);

  /*
  * Accordion Expand Icon
  */
  --accordion-expand-icon-size: var(--accordion-icon-size);
  --accordion-expand-icon-size-bg: 8px;
  --accordion-expand-icon-color-bg: var(--color-text-action-secondary);
  --accordion-expand-icon-spacing-text: 5px;
  --accordion-color-expand-icon: var(--color-text-action-secondary);
}
